import { Box, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FileDragAndDrop } from './FileDragAndDrop';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { BOL, POD } from '../../constants/enums';
import { getCodeReference } from '../../helpers/stringHelpers';
import loadApi from '../../api/loadApi';

export const FormPODAndBOD = (props) => {
    const {
        files,
        handleSetFiles,
        load
    } = props;

    const [currentTab, setCurrentTab] = useState(0);

    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result.split(',')[1];
                resolve(base64String);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    };

    const handleFileUploads = async(event) => {

        const file = event.target.files[0];
        const base64String = await readFileAsBase64(file);

        const uploadedFile = {
            customer : getCodeReference(load?.reference_id),
            context : currentTab === 0 ? POD.value : BOL.value,
            reference : load?.reference_id,
            name : file?.name,
            content : base64String
        }

        const result = await loadApi.uploadLoadFile(uploadedFile);
        console.log(result);
    }

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div role="tabpanel" hidden={value !== index} {...other}>
                {value === index && <Box p={3}>{children}</Box>}
            </div>
        );
    };

    return <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
                orientation="vertical"
                value={currentTab}
                variant="scrollable"
                allowScrollButtonsMobile
                onChange={(event, newVal) => setCurrentTab(newVal)}
            >
                <Tab label="Proof of Delivery" id="pod" />
                <Tab label="Bill of Lading" id="bod" />
            </Tabs>
        </Box>
        <TabPanel value={currentTab} index={0}>
            <FileDragAndDrop
                files={files?.pod}
                handleSetFiles={handleFileUploads}
                buttonLabel='Upload Proof of Delivery'
                icon={<InsertDriveFileIcon />}
            />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
            <FileDragAndDrop
                files={files?.bod}
                handleSetFiles={handleFileUploads}
                buttonLabel='Upload Bill of Landing'
                icon={<InsertDriveFileIcon />}
            />
        </TabPanel>
    </Box>;
}