// import "./css/custom-scrollbar.css";
import CssBaseLine from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppLayout from './components/layout/AppLayout';
import Home from './pages/Home';
import Board from './pages/Board';
import Time from './pages/Time';
import { LoadProvider } from './contexts/loads.context';
import Users from './pages/Users';
import Companies from './pages/Companies';
import Account from './pages/Account';
import UserActivity from './pages/UserActivity';
import Reports from './pages/Reports';
import './css/App.css';
import './css/Load.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { SocketProvider } from './contexts/socket.context';
import { TaskProvider } from './contexts/tasks.context';
import { SiteProvider } from './contexts/site.context';
import { useSelector } from 'react-redux';

LicenseInfo.setLicenseKey(
  '359f041c86974b07caea40468ac5b52aTz04ODEwNCxFPTE3NDQyOTA2MDMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
); // TODO: update to process.env.MUIX_LICENSE_KEY

function App() {
  const user = useSelector((state) => state.user.value);

  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#1059FB',
        darker: '#053e85',
      },
      secondary: {
        main: '##EEF0F3',
        contrastText: '#0A0B0D',
      },
      neutral: {
        main: '#64748B',
        contrastText: '#fff',
      },
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Noto Sans',
        'Helvetica',
        'Arial',
        'Roboto',
        'sans-serif',
      ].join(','),
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <SiteProvider>
          <SocketProvider>
            <LoadProvider>
              <TaskProvider>
                <BrowserRouter>
                  <Routes>
                    {user.isCustomer && (
                      <Route path="/" element={<AppLayout />}>
                        <Route index element={<Home />} />
                        <Route path="boards/:boardId" element={<Board />} />
                      </Route>
                    )}
                    {!user.isCustomer && (
                      <Route path="/" element={<AppLayout />}>
                        <Route index element={<Home />} />
                        <Route path="time" element={<Time />} />
                        <Route path="users" element={<Users />} />
                        <Route path="companies" element={<Companies />} />
                        <Route path="account" element={<Account />} />
                        <Route path="boards" element={<Home />} />
                        <Route path="boards/:boardId" element={<Board />} />
                        <Route
                          path="user-activity"
                          element={<UserActivity />}
                        />
                        <Route path="reports" element={<Reports />} />
                      </Route>
                    )}
                  </Routes>
                </BrowserRouter>
              </TaskProvider>
            </LoadProvider>
          </SocketProvider>
        </SiteProvider>
      </LocalizationProvider>
      <CssBaseLine />
    </ThemeProvider>
  );
}

export default App;
