import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
  IMessage,
} from '@novu/notification-center';
import { Box } from '@mui/material';
import { config } from '../../config';
import { useContext } from 'react';
import { LoadContext } from '../../contexts/loads.context';

const NotificationsMenu = (props) => {
  const { setEditLoad } = useContext(LoadContext);

  function onNotificationClick(message: IMessage) {
    // your logic to handle the notification click

    if (message.payload && message.payload.load) {
      setEditLoad(message.payload.load);
    }

    if (
      message?.cta?.data &&
      message?.cta?.data.url &&
      message?.cta?.data.url.length > 0
    ) {
      window.location.href = message.cta.data.url;
    }
  }

  return (
    <NovuProvider
      subscriberId={config.novu.subscriberId}
      applicationIdentifier={config.novu.applicationIdentifier}
      initialFetchingStrategy={{
        fetchNotifications: true,
        fetchUserPreferences: true,
      }}
    >
      <PopoverNotificationCenter
        colorScheme={'light'}
        footer={() => {}}
        onNotificationClick={onNotificationClick}
        showUserPreferences={false}
      >
        {({ unseenCount }) => (
          <Box sx={{ marginTop: 1 }}>
            <NotificationBell unseenCount={unseenCount} />
          </Box>
        )}
      </PopoverNotificationCenter>
    </NovuProvider>
  );
};

export default NotificationsMenu;
