import {
  Alert,
  Box,
  Button,
  Link,
  Snackbar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Divider,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../components/common/Loading';
import UserMenu from '../components/common/UserMenu';
import authApi from '../api/authApi';
import { useSearchParams } from 'react-router-dom';
import '../css/App.css';

const Account = () => {
  const user = useSelector((state) => state.user.value);
  let [searchParams, setSearchParams] = useSearchParams();
  const [title, setTitle] = useState('Account');
  const [userData, setUserData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    message: '',
  });

  useEffect(() => {
    if (searchParams.get('refresh_stripe') === 1) {
      onboard();
    } else {
      getUser();
    }
  }, []);

  const getUser = async () => {
    try {
      const user = await authApi.get();
      setUserData(user);
      if (!user.stripe_account || !user.stripe_account.details_submitted) {
        setTitle('Account - Stripe Onboarding Required');
        setSnackbar({
          open: true,
          severity: 'warning',
          message: 'Stripe onboarding required.',
        });
      }
    } catch (error) {
      console.error('Error getting user:', error);
      setSnackbar({
        open: true,
        severity: 'error',
        message: 'Error getting user.',
      });
    }
      setLoading(false);
  };

  const closeSnackbar = () => {
    setSnackbar({
      open: false,
      severity: 'success',
      message: '',
    });
  };

  const onboard = async () => {
    try {
      setSnackbar({
        open: true,
        severity: 'info',
        message: 'Redirecting you to Stripe Onboarding!',
      });
      const res = await authApi.stripeOnboard();
      if (res && res.url) {
        window.location.href = res.url;
      } else {
        console.error('Stripe onboarding URL not received');
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'Error redirecting to Stripe Onboarding.',
        });
      }
    } catch (error) {
      console.error('Error during Stripe onboarding:', error);
      setSnackbar({
        open: true,
        severity: 'error',
        message: 'Error redirecting to Stripe Onboarding.',
      });
    }
  };

  const renderStripeInfo = () => {
    if (!userData) return null;
    if (
      userData.stripe_account_id &&
      userData.stripe_account &&
      userData.stripe_account.details_submitted === true
    ) {
      return (
        <>
          Stripe Account:
          <Link
            href={userData.stripe_account_link}
            target="_blank"
            sx={{ marginLeft: 1 }}
          >
            Log In
          </Link>
        </>
      );
    } else {
      return (
        <Button
          size="small"
          color="primary"
          onClick={onboard}
          startIcon={<PersonAddIcon />}
        >
          Create Stripe Account
        </Button>
      );
    }
  };

  if (loading) {
    return <Loading fullHeight />;
  }

  return (
    <Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <AppBar position="static" color="inherit" className="appBar">
        <Toolbar>
          <Typography
            component="div"
            sx={{
              fontFamily: 'var(--system-ui)',
              fontSize: { xs: '1rem', md: '1.25rem' },
              flexGrow: '1',
              mr: '1rem',
              fontWeight: 'bolder',
              whiteSpace: 'nowrap',
            }}
          >
            <Typography variant="subtitle2">
              Load Manager Back Office{' '}
            </Typography>
            {title}
          </Typography>
          <Box className="userMenu">
            <UserMenu />
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ maxWidth: 360, mx: 'auto', mt: 6 }}>
        <Card variant="outlined">
          <CardHeader
            action={
              <IconButton aria-label="settings" size="small" disabled>
                <EditIcon />
              </IconButton>
            }
            subheader="Account Details"
          />
          <Divider light variant="fullwidth" />
          <CardContent>
            <TextField
              fullWidth
              disabled
              margin="normal"
              label="First Name"
              id="firstname"
              value={user.firstname}
            />
            <TextField
              fullWidth
              disabled
              margin="normal"
              label="Last Name"
              id="lastname"
              value={user.lastname}
            />
            <TextField
              fullWidth
              disabled
              margin="normal"
              label="Email"
              id="email"
              value={user.email}
            />
          </CardContent>
          <Divider light variant="fullwidth" />
          <Typography
            sx={{ fontSize: 14, px: 2, py: 1, mt: 1 }}
            color="text.secondary"
          >
            Stripe Information
          </Typography>
          <CardActions sx={{ px: 2 }}>{renderStripeInfo()}</CardActions>
        </Card>
      </Box>
    </Box>
  );
};

export default Account;
