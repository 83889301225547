export const CRUD_ACTIONS = Object.freeze({
    ADD: 'add',
    EDIT: 'edit',
    REMOVE: 'remove'
});

export const CRM_ACTIONS = [
    {value: 'email', name: 'Email'},
    {value: 'call', name: 'Call'},
    {value: 'messaged', name: 'Messaged'},
];

export const POD = {value: 'POD', label: 'Proof of Delivery' };
export const BOL = {value: 'BOL', label: 'Bill of Lading' };