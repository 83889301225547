import React, { useState, useEffect, useMemo } from "react";
import { io } from "socket.io-client";
import { config } from "../config";
import { getToken } from "../api/axiosClient";

const SocketContext = React.createContext();

const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(undefined);
  const [showConnectionError, setShowConnectionError] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    const conn = io(config.api, {
      transports: ["websocket"],
    });

    let timer = null;
    conn.on("connect", async () => {
      setSocket(conn);

      clearTimeout(timer);
      setShowConnectionError(false);
     
      const token = await getToken();
      if (token) {
        conn.emit("group_join", {token});
      }
    });

    conn.on("connect_error", (err) => {
      timer = setTimeout(() => {
        setShowConnectionError(true);
      }, 2000);
    });
  };
  const values = useMemo(
    () => ({ socket, showConnectionError }),
    [socket, showConnectionError]
  );

  return (
    <SocketContext.Provider value={values}>{children}</SocketContext.Provider>
  );
};
export { SocketContext, SocketProvider };
