import { Box, Button, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import React, { Fragment } from 'react';

export const FileDragAndDrop = (props) => {

    const {
        files,
        handleSetFiles,
        buttonLabel = 'Upload File',
        icon
    } = props;

    return <Fragment>
        <Button
            variant="contained"
            component="label"
        >
            {buttonLabel}
            <input
                type="file"
                hidden
                onChange={(event) => handleSetFiles(event)}
            />
        </Button>
        <List dense={true}>
            {files?.map((file, index) => {
                return <ListItem key={`file-index=${index}`}>
                    {icon &&
                        <ListItemIcon>
                            {icon}
                        </ListItemIcon>
                    }
                    <ListItemText
                        primary={file}
                    />
                </ListItem>
            })}
        </List>

    </Fragment>
}