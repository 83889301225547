import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import userApi from '../api/userApi';
import UserMenu from '../components/common/UserMenu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import accessUtils from '../utils/accessUtils';
import { CustomPopover } from '../components/generic/CustomPopover';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #333',
  borderRadius: '4px',
  boxShadow: 24,
  p: 4,
};

const Users = () => {
  const [title, setTitle] = useState('Users');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(undefined);
  const [newUser, setNewUser] = useState('');
  const [editUser, setEditUser] = useState(undefined);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null)
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    message: '',
  });

  const [open, setOpen] = useState(false);

  const fetchUsers = async () => {
    const res = await userApi.getAll();
    setUsers(res);
    if (process.env.NODE_ENV === 'development') {
      // console.log('users', res);
    }
  };

  useEffect(() => {
    if (!users) fetchUsers();
  }, [users]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate('/users');
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'role') {
      setNewUser((prevState) => ({ ...prevState, groups: value }));
    } else {
      setNewUser((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleSubmit = async (newUser) => {

    const user = {
      firstname: newUser.firstname,
      lastname: newUser.lastname,
      email: newUser.email,
      groups: newUser.groups,
    };

    try {
      const res = await userApi.create(user);
      await fetchUsers();
    } catch (error) {
      console.error(error);
    } finally {
      handleClose();
      setSnackbar({
        open: true,
        severity: 'success',
        message: 'Saved!',
      });
    }
  };

  const handleDelete = async (user) => {
    try {      
      const result = await userApi.delete(user);
      await fetchUsers();
      setSnackbar({
        open: true,
        severity: 'success',
        message: 'Successfully removed User'
      });
      setAnchorEl(null);
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'warning',
        message: 'Unable to delete user'
      });
    }
  }

  const handleOpenPopover = (event, user) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setSelectedUser(null)
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      valueGetter: (params) =>
        params.row.firstname +
        (params.row.lastname ? ' ' + params.row.lastname : ''),
      flex: 1,
    },
    {
      field: 'role',
      headerName: 'Role',
      valueGetter: (params) => {
        const roles = [
          { check: accessUtils.isAdmin, value: 'Admin' },
          { check: accessUtils.isStaff, value: 'Staff' },
          { check: accessUtils.isCustomer, value: 'Customer' },
        ];
        const userRoles = roles
          .filter((role) => role.check(params.row))
          .map((role) => role.value);
        return userRoles.length > 0 ? userRoles.join(', ') : 'Unknown';
      },
      flex: 1,
    },
    { field: 'email', headerName: 'Email', flex: 1 },
    {
      field: 'actions',
      headerName: 'Rates',
      renderCell: (params) => <Fragment>
          { user.isAdmin &&
            <IconButton
              size="small"
              onClick={() => {
                const userCopy = { ...params.row };
                if (!userCopy.rate) {
                  userCopy.rate = {
                    hourly: 0,
                    holiday_hourly: 0,
                    overtime_hourly: 0,
                  };
                }
                setEditUser(userCopy);
              }}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          }
        </Fragment>,
      sortable: false,
      flex: 0.5,
    },
    {
      field: 'user_actions',
      headerName: 'Actions',
      flex:0.5,
      renderCell: (cell) => <Fragment>
          <IconButton size="small" onClick={(event)=>handleOpenPopover(event, cell?.row)}>
            <DeleteIcon fontSize="inherit" color='error' />
          </IconButton>
          <CustomPopover
            anchorEl={anchorEl}
            handleClose={handleClosePopover}
          >
            <Box sx={{ m: "15px" }}>
              <Typography>Are you sure you want to delete this user?</Typography>
              <Typography style={{ fontStyle: "italic" }}>
                You won't be able to retrieve this data.
              </Typography>
              <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                <Button onClick={()=>handleDelete(selectedUser)}>Yes</Button>
                <Button color="error" onClick={()=>handleClosePopover()}>No</Button>
              </Box>
            </Box>
          </CustomPopover>
        </Fragment>
    }
  ];

  const rows = (users || []).map((userRec) => ({
    ...userRec,
    id: userRec._id,
  }));

  const fields = [
    { name: 'firstname', label: 'First Name', required: true },
    { name: 'lastname', label: 'Last Name' },
    { name: 'email', label: 'Email', required: true },
  ];

  const closeSnackbar = () => {
    setSnackbar({
      open: false,
      severity: 'success',
      message: '',
    });
  };

  const saveEditUser = async () => {
    const res = await userApi.update(editUser);
    await fetchUsers();
    setEditUser(undefined);
    setSnackbar({
      open: true,
      severity: 'success',
      message: 'Saved!',
    });
  };

  return (
    <Box width={'100vw'}>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <AppBar
        position="static"
        color="inherit"
        className="appBar"
        sx={{ maxWidth: '100vw' }}
      >
        <Toolbar>
          <Typography
            component="div"
            sx={{
              fontFamily: 'var(--system-ui)',
              fontSize: { xs: '1rem', md: '1.25rem' },
              flexGrow: '1',
              mr: '1rem',
              fontWeight: 'bolder',
              whiteSpace: 'nowrap',
            }}
          >
            <Typography variant="subtitle2">
              Load Manager Back Office{' '}
            </Typography>
            {title}
          </Typography>
          <Box className="userMenu">
            <UserMenu />
          </Box>
        </Toolbar>
      </AppBar>
      <Container
        maxWidth="false"
        sx={{
          padding: {
            xs: 1,
            md: 2,
            backgroundColor: 'var(--backgroundSecondary)',
          },
        }}
      >
        {user.isAdmin && (
          <>
            <Grid container spacing={2} sx={{ marginBottom: 2 }} justifyContent="flex-end">
              <Grid item>
                <Button variant="contained" color="primary" size="small" disableElevation onClick={handleOpen}>
                  Add User
                </Button>
              </Grid>
              <Grid item>
                <Button size="small" disableElevation onClick={() => navigate('/time')}>
                  View Time
                </Button>
              </Grid>
            </Grid>

            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Add User</DialogTitle>
              <DialogContent>
                <form onSubmit={handleSubmit}>
                  {fields.map((field) => (
                    <TextField
                      key={field.name}
                      name={field.name}
                      label={field.label}
                      value={newUser[field.name]}
                      onChange={handleChange}
                      fullWidth
                      size="small"
                      required={field.required}
                      sx={{ my: 1 }}
                    />
                  ))}
                  <Select
                    name="role"
                    displayEmpty
                    value={newUser.groups || []}
                    onChange={handleChange}
                    fullWidth
                    required
                    size="small"
                    multiple
                    sx={{ my: 1 }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <Typography
                            color={(theme) => theme.palette.grey[500]}
                          >
                            Select group(s)
                          </Typography>
                        );
                      }

                      return selected.join(', ');
                    }}
                  >
                    <MenuItem disabled value="">
                      Select group(s)
                    </MenuItem>
                    <MenuItem value="LM_Guest">Customer</MenuItem>
                    <MenuItem value="Load Manager Support">Support</MenuItem>
                    <MenuItem value="LM_Staff">Staff</MenuItem>
                    <MenuItem value="LM_Admin">Admin</MenuItem>
                  </Select>
                  <DialogActions>
                    <Button onClick={handleClose} disableElevation fullWidth>
                      Cancel
                    </Button>
                    <Button type="submit" variant="contained" disableElevation fullWidth>
                      Add
                    </Button>
                  </DialogActions>
                </form>
              </DialogContent>
            </Dialog>
          </>
        )}
        <Box sx={{ backgroundColor: 'white', height:'600px' }}>
          <DataGridPro
            rows={rows}
            columns={columns}
            loading={loading}
            pageSize={20}
            rowsPerPageOptions={[20]}
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            sx={{
              border: 'none',
              padding: { xs: 1, md: 2 },
            }}
          />
          {editUser && (
            <Modal
              open={editUser !== undefined}
              onClose={() => setEditUser(undefined)}
            >
              <Box sx={style}>
                <Typography>Rates</Typography>
                <Divider sx={{ marginY: 2 }} />
                <FormControl sx={{ marginY: 1 }}>
                  <TextField
                    value={editUser.rate.hourly}
                    type="number"
                    label="Hourly (USD)"
                    size="small"
                    onChange={(event) => {
                      const userCopy = { ...editUser };
                      userCopy.rate.hourly = parseFloat(event.target.value);
                      setEditUser(userCopy);
                    }}
                  />
                </FormControl>
                <FormControl sx={{ marginY: 1 }}>
                  <TextField
                    value={editUser.rate.holiday_hourly}
                    type="number"
                    label="Holiday Hourly (USD)"
                    size="small"
                    onChange={(event) => {
                      const userCopy = { ...editUser };
                      userCopy.rate.holiday_hourly = parseFloat(
                        event.target.value,
                      );
                      setEditUser(userCopy);
                    }}
                  />
                </FormControl>
                <FormControl sx={{ marginY: 1 }}>
                  <TextField
                    value={editUser.rate.overtime_hourly}
                    type="number"
                    label="Overtime Hourly (USD)"
                    size="small"
                    onChange={(event) => {
                      const userCopy = { ...editUser };
                      userCopy.rate.overtime_hourly = parseFloat(
                        event.target.value,
                      );
                      setEditUser(userCopy);
                    }}
                  />
                </FormControl>
                <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    size="small"
                    disableElevation
                    fullWidth
                    onClick={() => setEditUser(undefined)}
                  >
                    Close
                  </Button>

                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disableElevation
                    fullWidth
                    onClick={saveEditUser}
                    sx={{ marginLeft: 1 }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Modal>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Users;
