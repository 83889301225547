import { Box, CircularProgress } from "@mui/material";

const Loading = (props) => {
  if (props.show || props.show === undefined) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: props.fullHeight ? "100vh" : "100%",
        }}
      >
        <CircularProgress color="neutral" />
      </Box>
    );
  }
};

export default Loading;
