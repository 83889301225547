import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/userSlice";
import boardReducer from "./features/boardSlice";
import { loadSlice, filterLoadSlice } from "./features/loadSlice";
import companyReducer from "./features/companySlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    board: boardReducer,
    loads: loadSlice.reducer,
    companies: companyReducer,
  },
});
