import authApi from "../api/authApi"

const authUtils = {
  isAuthenticated: async () => {
    try {
      const res = await authApi.verifyToken()
      return res.user
    } catch {
      return false
    }
  }
}

export default authUtils