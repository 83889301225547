import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchIcon from '@mui/icons-material/Search';
import '../css/App.css';
import '../css/Board.css';
import {
  Alert,
  AlertTitle,
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
  AppBar,
  Toolbar,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import boardApi from '../api/boardApi';
import Loading from '../components/common/Loading';
import loadApi from '../api/loadApi';
import taskApi from '../api/taskApi';
import companyApi from '../api/companyApi';
import { setCompanies } from '../redux/features/companySlice';
import { LoadContext } from '../contexts/loads.context';
import NotificationsMenu from '../components/common/NotificationsMenu';
import UserMenu from '../components/common/UserMenu';
import Sections from '../components/common/Sections';
import { TaskContext } from '../contexts/tasks.context';
import { SocketContext } from '../contexts/socket.context';
import { BoardType } from '../constants/boardTypes';

const debounce = (func, timeout = 1000) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

let reloadCompanies = null;

const Board = () => {
  const dispatch = useDispatch();
  const loadContext = useContext(LoadContext);
  const taskContext = useContext(TaskContext);
  const socketContext = useContext(SocketContext);

  const companies = useSelector((state) => state.companies.value);
  const user = useSelector((state) => state.user.value);

  const { boardId } = useParams();
  const [title, setTitle] = useState('');
  const [sections, setSections] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [searchVal, setSearchVal] = useState('');
  const [items, setItems] = useState([]);
  const [boardType, setBoardType] = useState();
  const [api, setApi] = useState();
  const [context, setContext] = useState();

  useEffect(() => {
    if (!reloadCompanies) reloadCompanies = loadContext.reloadCompanies;

    if (reloadCompanies !== loadContext.reloadCompanies) {
      reloadCompanies = loadContext.reloadCompanies;
      getCompanies();
    }
    if (!sections) getBoard();
  }, [boardId, loadContext.reloadCompanies]);

  useEffect(() => {
    if (boardType === BoardType.Task) setItems(taskContext.items);
  }, [taskContext.items]);

  useEffect(() => {
    if (boardType === BoardType.Load) runSearch();
  }, [loadContext.items]);

  const getBoard = async () => {
    try {
      const res = await boardApi.getOne(boardId);
      await getCompanies();
      setBoardType(res.type);

      let allItems = [];
      let context = null;
      if (res.type === BoardType.Task) {
        allItems = await taskApi.getAll();
        context = taskContext;
        setApi(taskApi);
      } else {
        allItems = await loadApi.getAll(boardId);
        context = loadContext;
        setApi(loadApi);
      }

      context.setItems(allItems);

      setContext(context);
      setItems(allItems);
      setSections(res.sections);
      setTitle(res.title);
      setLoading(false);
    } catch (err) {
      console.error(err);
      alert(err.data);
    }
  };

  const getCompanies = async () => {
    const companies = await companyApi.getAll();
    dispatch(setCompanies(companies));
  };

  const runSearch = (searchValue = null) => {
    if (searchValue === null) searchValue = searchVal;
    if (searchValue && searchValue.length > 0) {
      const foundLoads = loadContext.items.filter((load) => {
        const loadMatch =
          String(load.qe_id).indexOf(searchValue) >= 0 ||
          String(load.po_number).indexOf(searchValue) >= 0 ||
          String(load.reference_id).indexOf(searchValue) >= 0 ||
          (load.notes && load.notes.indexOf(searchValue) >= 0);
        if (!loadMatch) {
          return companies.find((company) => {
            if (
              company.id === load.customer ||
              company.id === load.shipper ||
              company.id === load.carrier ||
              company.id === load.receiver
            ) {
              return company.name.indexOf(searchValue) >= 0;
            }
            return false;
          });
        } else return true;
      });

      setItems(foundLoads);
    } else setItems([...loadContext.items]);
  };

  const searchDebounce = useCallback(debounce(runSearch), [loadContext.items]);

  if (loading) {
    return <Loading fullHeight />;
  }
  
  return (
    <Box>
      <AppBar position="fixed" color="inherit" className="appBar">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Typography
            component="div"
            sx={{
              fontFamily: 'var(--system-ui)',
              fontSize: { xs: '1rem', md: '1.25rem' },
              mr: '1rem',
              fontWeight: 'bolder',
              whiteSpace: 'nowrap',
              color: 'neutral.main',
            }}
          >
            {title}
          </Typography>
          {boardType !== BoardType.Task && (
            <Box>
              <FormControl variant="outlined">
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  placeholder="Search"
                  onChange={(event) => {
                    setSearchVal(event.target.value);
                    searchDebounce(event.target.value);
                  }}
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    id: 'searchField',
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" color="disabled" />
                      </InputAdornment>
                    ),
                    endAdornment:
                      searchVal.length > 0 ? (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={(event) => {
                              setSearchVal('');
                              document.getElementById('searchField').value = '';
                              runSearch('');
                            }}
                          >
                            <CloseOutlinedIcon size="small" />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        <></>
                      ),
                  }}
                />
              </FormControl>
            </Box>
          )}
          {!user.isCustomer && (
            <Box sx={{ display: 'flex' }}>
              <Box className="notificationsMenu">
                <NotificationsMenu />
              </Box>
              <Box className="userMenu">
                <UserMenu />
              </Box>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Box>
        <Sections
          sections={sections}
          items={items}
          boardId={boardId}
          api={api}
          context={context}
          boardType={boardType}
        />
      </Box>
      <Snackbar
        open={socketContext.showConnectionError}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          <AlertTitle>Server Disconnected</AlertTitle>
          Updates Will Not Appear
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Board;
