import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { store } from './redux/store'
import { Provider } from 'react-redux';
import { config } from "./config";
import { PublicClientApplication } from "@azure/msal-browser";

export const msalInstance = new PublicClientApplication(config.sso.msalConfig);

// Polyfill for window.crypto.randomUUID
// if (!window.crypto.randomUUID) {
//   window.crypto.randomUUID = function() {
//       let random = window.crypto.getRandomValues(new Uint8Array(16));
//       random[6] = (random[6] & 0x0f) | 0x40; // Version 4
//       random[8] = (random[8] & 0x3f) | 0x80; // Variant 10
//       return [...random].map((v) => v.toString(16).padStart(2, '0')).join('');
//   }
// }

msalInstance.initialize().then(() => {
  msalInstance.handleRedirectPromise().then(async (res) => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length === 0) {
      await msalInstance.loginRedirect({scopes: config.sso.scopes})
    } else {
      const root = ReactDOM.createRoot(document.getElementById('root'));
      root.render(
        <Provider store={store}>
          <App />
        </Provider>
      );
    }
  }).catch(err => console.error(err));
});
