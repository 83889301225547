import { createSlice } from "@reduxjs/toolkit";

export const loadSlice = createSlice({
  name: "loads",
  initialState: { value: [] },
  reducers: {
    setLoads: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setLoads } = loadSlice.actions;
