export const BoardType = {
  Task: 'task',
  Load: 'load',
};

export const LoadMode = {
  FTL: 'FTL',
  Partial: 'Partial',
  LTL: 'LTL',
};
