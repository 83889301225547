import React, { useEffect, useState, useContext, useMemo } from "react";
import { SocketContext } from "./socket.context";
import Moment from "moment";

const TaskContext = React.createContext();

const TaskProvider = (props) => {
  const { socket } = useContext(SocketContext);

  const [items, setItems] = useState([]);
  const [editItem, setEditItem] = useState();

  useEffect(() => {
    if (socket) {
      socket.on("taskCreate", (task) => {
        addTask(task);
      });

      socket.on("taskUpdate", (task) => {
        updateTask(task);
      });

      socket.on("taskDelete", (task) => {
        deleteTask(task);
      });

      return () => {
        socket.off("taskCreate");
        socket.off("taskUpdate");
        socket.off("taskDelete");
      };
    }
  }, [items, socket]);

  const updateTask = (taskUpdate) => {
    const tasksCopy = items.map((task) => {
      if (Array.isArray(taskUpdate)) {
        const foundTask = taskUpdate.find((taskUp) => taskUp._id === task._id);
        console.log(foundTask);
        if (foundTask) return foundTask;
        else return task;
      } else if (task._id === taskUpdate._id) return taskUpdate;
      else return task;
    });
    setItems(tasksCopy);
  };

  const addTask = (taskAdd) => {
    const tasksCopy = [...items];
    const alreadyExists = tasksCopy.find((task) => task._id === taskAdd._id);
    if (!alreadyExists) {
      tasksCopy.push(taskAdd);
      setItems(tasksCopy);
    }
  };

  const deleteTask = (taskDelete) => {
    const tasksCopy = items
      .filter((task) => taskDelete.task._id !== task._id)
      .map((task) => {
        const foundTask = taskDelete.tasks.find(
          (taskUp) => taskUp._id === task._id
        );
        if (foundTask) return foundTask;
        else return task;
      });
    setItems(tasksCopy);
  };

  const sort = (items) => {
    return items.sort((a, b) => {
      if (!a.qe_id) return -1;
      else if (!b.qe_id) return 1;
      else if (!a.alert && !b.alert)
        return parseInt(a.qe_id) - parseInt(b.qe_id);
      else if (!a.alert && b.alert) return 1;
      else if (a.alert && !b.alert) return -1;
      else {
        return Moment(a.alert).valueOf() - Moment(b.alert).valueOf();
      }
    });
  };

  const values = useMemo(
    () => ({
      items,
      setItems,
      updateTask,
      addTask,
      deleteTask,
      editItem,
      setEditItem,
      sort,
    }),
    [items]
  );

  return (
    <TaskContext.Provider value={values}>{props.children}</TaskContext.Provider>
  );
};

export { TaskContext, TaskProvider };
