import {
  Alert,
  AppBar,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  Snackbar,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../components/common/Loading";
import MenuItem from "@mui/material/MenuItem";
import timeApi from "../api/timeApi";
import { DateTime, Interval } from "luxon";
import UserMenu from "../components/common/UserMenu";
import { config } from "../config";
import LoadingButton from "@mui/lab/LoadingButton";
import UserTimeModal from "../components/common/UserTimeModal";

const initDate = "2022-10-30";
const rates = [
  {
    label: "Regular",
    rate: "hourly",
    value: "hours",
  },
  {
    label: "Overtime",
    rate: "overtime_hourly",
    value: "overtime_hours",
  },
  {
    label: "Holiday",
    rate: "holiday_hourly",
    value: "holiday_hours",
  },
];
const defaultTime = {
  _id: null,
  user: null,
  hours: 0,
  overtime_hours: 0,
  holiday_hours: 0,
  date: null,
  stripe_payment_id: null,
};

const Time = () => {
  const user = useSelector((state) => state.user.value);
  const [title, setTitle] = useState("Time");
  const [loading, setLoading] = useState(true);
  const [times, setTimes] = useState(undefined);
  const [periods, setPeriods] = useState([]);
  const [selectedWorkWeek, setSelectedWorkWeek] = useState("");
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedUserTime, setSelectedUserTime] = useState(null);
  const [isApproving, setApproving] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  useEffect(() => {
    if (!times) init();
  }, [selectedWorkWeek, times]);

  const getTimes = async (params) => {
    try {
      const res = await timeApi.getAll(params);
      setTimes(res);
      return res;
    } catch (err) {
      console.error(err);
    }
  };

  const init = async () => {
    const interval = Interval.fromDateTimes(
      DateTime.fromSQL(initDate),
      DateTime.now(),
    );

    const weeks = interval.splitBy({ weeks: 1 }).map((d) => d.start);
    const latestWeek = weeks[weeks.length - 1].toSQLDate();
    setPeriods(weeks.map((date) => date.toSQLDate()));
    if (!selectedWorkWeek) {
      if (user.isAdmin) {
        const times = await getTimes({ date: latestWeek });
      } else {
        const times = await getTimes();
        weeks.map((date) => {
          const weekDate = date.toSQLDate();
          const timeExists = times.find((time) => time.date === weekDate);
          if (!timeExists) {
            const newTime = { ...defaultTime };
            newTime.user = user._id;
            newTime.date = weekDate;
            const timesCopy = [...times];
            timesCopy.push(newTime);
            setTimes(timesCopy);
            setSelectedTime(newTime);
          }
          return null;
        });
      }

      setSelectedWorkWeek(latestWeek);
      setLoading(false);
    }
  };

  const changeSelectedTime = async (newDate) => {
    if (user.isAdmin) await getTimes({ date: newDate });
    const selected = times.find((time) => time.date === newDate);
    setSelectedTime(selected);
  };

  const getTotal = () => {
    let total = rates.reduce((total, rate) => {
      total += selectedTime[rate.value] * user.rate[rate.rate];
      return total;
    }, 0);
    return Math.ceil(total * 10) / 10;
  };

  const submit = async () => {
    const selectedTimeCopy = { ...selectedTime };
    selectedTimeCopy.total = getTotal();
    setSnackbar({
      open: true,
      severity: "info",
      message: "Saving!",
    });
    try {
      await timeApi.create(selectedTimeCopy);
      setSnackbar({
        open: true,
        severity: "success",
        message: "Saved!",
      });
      await getTimes({ date: selectedWorkWeek });
      closeSnackbar();
    } catch (err) {
      setSnackbar({
        open: true,
        severity: "error",
        message: err.data.error,
      });
    }
  };

  const closeSnackbar = () => {
    setTimeout(() => {
      setSnackbar({
        open: false,
        severity: "success",
        message: "",
      });
    }, 2000);
  };

  const approve = async (timeId) => {
    setApproving(true);
    setSnackbar({
      open: true,
      severity: "info",
      message: "Approving!",
    });
    try {
      await timeApi.approve(timeId);
      setSnackbar({
        open: true,
        severity: "success",
        message: "Approved!",
      });
      await getTimes({ date: selectedWorkWeek });
      closeSnackbar();
    } catch (err) {
      setSnackbar({
        open: true,
        severity: "error",
        message: err.data.error,
      });
    } finally {
      setApproving(false);
    }
  };

  const hasPendingApprovals = () => {
    if (times) return times.some((time) => !time.stripe_payment_id);
    return false;
  };

  const approveAll = async () => {
    setApproving(true);
    setSnackbar({
      open: true,
      severity: "info",
      message: "Approving all!",
    });
    try {
      // TODO: Get bulk approval wired in API
      for (let time of times) {
        if (!time.stripe_payment_id) {
          await timeApi.approve(time._id);
        }
      }
      setSnackbar({
        open: true,
        severity: "success",
        message: "All approved!",
      });
      await getTimes({ date: selectedWorkWeek });
      closeSnackbar();
    } catch (err) {
      setSnackbar({
        open: true,
        severity: "error",
        message: err.data.error,
      });
    } finally {
      setApproving(false);
    }
  };
  const currencyFormat = (amount) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
  };
  const workWeekSelect = () => {
    return (
      <FormControl sx={{ minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small">Work Week</InputLabel>
        <Select
          value={selectedWorkWeek}
          label={"Work Week"}
          onChange={(event) => {
            setSelectedWorkWeek(event.target.value);
            changeSelectedTime(event.target.value);
          }}
        >
          {periods.map((date, i) => {
            return (
              <MenuItem key={`${date}-${i}`} value={date}>
                {date}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const showUser = () => {
    if (
      user.rate &&
      user.rate.hourly &&
      user.rate.holiday_hourly &&
      user.rate.overtime_hourly
    ) {
      return (
        <Grid container>
          <Grid item xs={12} md={6} sx={{ paddingX: 2 }}>
            <Grid container>
              <Grid item xs={12} sx={{ paddingY: 2 }} display='flex' justifyContent={'space-between'}>
                <Typography variant="h5">Submit Payroll</Typography>
                {workWeekSelect()}
              </Grid>
              <Divider sx={{ marginTop: 2 }} />
              <Grid item xs={3}>
                Type
              </Grid>
              <Grid item xs={3}>
                Time
              </Grid>
              <Grid item xs={3}>
                Rate
              </Grid>
              <Grid item xs={3}>
                Amount
              </Grid>
              <Grid item xs={12} sx={{ paddingY: 2 }}>
                <Divider />
              </Grid>
              {rates.map((rate, i) => {
                return (
                  <Grid item key={i} xs={12}>
                    <Grid container>
                      <Grid item xs={3} sx={{ paddingTop: 2 }}>
                        {rate.label}
                      </Grid>
                      <Grid item xs={3} sx={{ paddingY: 1 }}>

                        {selectedTime && !selectedTime.stripe_payment_id ? (
                          <TextField
                            sx={{ maxWidth: "100px" }}
                            value={selectedTime ? selectedTime[rate.value] : 0}
                            variant="outlined"
                            label="Hours"
                            type="number"
                            size="small"
                            onChange={(event) => {
                              const selectedTimeCopy = { ...selectedTime };
                              selectedTimeCopy[rate.value] = event.target.value;
                              setSelectedTime(selectedTimeCopy);
                            }}
                          />
                        ) : (
                          <Box sx={{ paddingTop: 1 }}>
                            {selectedTime && selectedTime[rate.value]}
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={3} sx={{ paddingTop: 2 }}>
                        {currencyFormat(user.rate[rate.rate])}
                      </Grid>
                      <Grid item xs={3} sx={{ paddingTop: 2 }}>
                        {currencyFormat(
                          selectedTime
                            ? selectedTime[rate.value] * user.rate[rate.rate]
                            : 0,
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
              <Grid item xs={12} sx={{ paddingY: 2 }}>
                <Divider />
              </Grid>
              <Grid item xs={9}>
                Total (USD)
              </Grid>
              <Grid item xs={3}>
                {currencyFormat(selectedTime ? getTotal() : 0)}
              </Grid>
              <Grid item xs={9}></Grid>
              <Grid item xs={3} sx={{ paddingTop: 3 }}>
                {selectedTime && !selectedTime.stripe_payment_id && (
                  <Button color="primary" variant="contained" onClick={submit}>
                    Submit Time
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} sx={{ paddingX: 2 }}>
            <Grid
              container
              sx={{
                overflowY: "scroll",
                maxHeight: "calc(100vh - 100px)",
                borderBottom: "1px solid #DDD",
              }}
            >
              <Grid item xs={12} sx={{ paddingY: 2 }}>
                <Typography variant="h5">Payroll History:</Typography>
                <Divider sx={{ marginTop: 2 }} />
              </Grid>
              {times.length > 0 ? (
                <>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      paddingX: 2,
                      paddingBottom: 2,
                      fontWeight: "bold",
                    }}
                  >
                    Week
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ paddingBottom: 2, fontWeight: "bold" }}
                  >
                    Total (USD)
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ paddingBottom: 2, fontWeight: "bold" }}
                  >
                    Status
                  </Grid>
                  {times
                    .filter((time) => time.total !== undefined)
                    .map((time, i) => {
                      return (
                        <Grid item xs={12} key={`${time.date}-${i}`}>
                          <Grid
                            container
                            sx={{
                              paddingX: 2,
                              paddingY: 1,
                              borderBottom: "1px solid #DDD",
                            }}
                          >
                            <Grid item xs={4}>
                              {time.date}
                            </Grid>
                            <Grid item xs={4}>
                              {currencyFormat(time.total)}
                            </Grid>
                            <Grid item xs={4}>
                              {time.stripe_payment_id ? (
                                <Chip
                                  sx={{ height: "22px" }}
                                  label="Approved"
                                  color="success"
                                  variant="outlined"
                                />
                              ) : (
                                <Chip
                                  sx={{ height: "22px" }}
                                  label="Pending"
                                  color="warning"
                                  variant="outlined"
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                </>
              ) : (
                <Alert severity="info">No Payroll History</Alert>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      return <></>;
    }
  };

  const showAdmin = () => {
    const columns = ["Staff", ...rates, "Total (USD)", "Actions"];
    return <Fragment>
      {showUser()}
      <Divider />
      <Grid container sx={{ paddingBottom: 2 }}>
        <Grid container item sx={{ justifyContent: "space-between", my: 2 }}>
          <Grid item>{workWeekSelect()}</Grid>
          <Grid item>
            <LoadingButton
              variant="contained"
              color="success"
              disableElevation
              loading={isApproving}
              onClick={approveAll}
              disabled={!hasPendingApprovals()}
            >
              Approve All
            </LoadingButton>
          </Grid>
        </Grid>
        <Paper
          elevation={0}
          variant="outlined"
          sx={{
            width: "100%",
            p: 3,
          }}
        >
          {times && times.length === 0 && (
            <Alert variant="outlined" color="warning" sx={{ width: "100%" }}>
              No Times Submitted Yet!
            </Alert>
          )}
          {times && times.length > 0 && (
            <Grid container direction="row">
              {columns.map((col, i) => {
                return (
                  <Grid
                    item
                    key={`${col.label}-${i}`}
                    xs={12 / columns.length}
                    sx={{
                      paddingBottom: 2,
                      fontWeight: "bold",
                    }}
                  >
                    {col.label ? col.label + " (Rate)" : col}
                  </Grid>
                );
              })}
            </Grid>
          )}
          {times &&
            times.length > 0 &&
            times
              .filter((time) => time.user)
              .map((time, i) => {
                return (
                  <Grid container item xs={12} key={`${time._id}-${i}`}>
                    <Grid
                      container
                      item
                      sx={{
                        paddingY: 1,
                        borderBottom: "1px solid #DDD",
                      }}
                    >
                      <Grid item xs={2}>
                        {time.user.firstname + " " + time.user.lastname}
                      </Grid>
                      {rates.map((rate) => {
                        return (
                          <Grid
                            item
                            key={"rate_" + time._id + rate.value}
                            xs={2}
                          >
                            {time[rate.value]}
                            <Box
                              sx={{
                                color: "#888",
                                display: "inline-block",
                                marginLeft: 1,
                              }}
                            >
                              ({currencyFormat(time.user.rate[rate.rate])})
                            </Box>
                          </Grid>
                        );
                      })}
                      <Grid item xs={2}>
                        {currencyFormat(time.total)}
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          sx={{ marginRight: 1}}
                          variant="contained"
                          size="small"
                          disableElevation
                          onClick={() => setSelectedUserTime(time)}
                        >
                          Edit
                        </Button>
                        {!time.stripe_payment_id ? (
                          <LoadingButton
                            variant="contained"
                            color="success"
                            size="small"
                            disableElevation
                            loading={isApproving}
                            onClick={() => approve(time._id)}
                          >
                            Approve
                          </LoadingButton>
                        ) : (
                          <Button
                            size="small"
                            target="_blank"
                            href={
                              config.stripePaymentLink +
                              "/" +
                              time.stripe_payment_id
                            }
                          >
                            View
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
        </Paper>
      </Grid>
    </Fragment>;
  };

  if (loading) {
    return <Loading fullHeight />;
  }
  console.log({
    isAdmin: user.isAdmin,
    isStaff: user.isStaff,
    isCustomer: user.isCustomer
  });
  return (
    <Box>
      <Snackbar
        open={snackbar.open}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <UserTimeModal
        isOpen={selectedUserTime}
        time={selectedUserTime}
        onTimeDeleted={async () => {
          await getTimes(selectedTime);
          setSelectedUserTime(null);
        }}
        onTimeSaved={async () => {
          await getTimes(selectedTime);
          setSelectedUserTime(null);
        }}
        onClose={() => setSelectedUserTime(null)} />
      <AppBar position="static" color="inherit" className="appBar">
        <Toolbar>
          <Typography
            component="div"
            sx={{
              fontFamily: "var(--system-ui)",
              fontSize: { xs: "1rem", md: "1.25rem" },
              flexGrow: "1",
              mr: "1rem",
              fontWeight: "bolder",
              whiteSpace: "nowrap",
            }}
          >
            <Typography variant="subtitle2">
              Load Manager Back Office{" "}
            </Typography>
            {title}
          </Typography>
          <Box className="userMenu">
            <UserMenu />
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          padding: { xs: 2, md: 3 },
          backgroundColor: "var(--backgroundSecondary)",
        }}
      >
        {((user.isAdmin && user.isStaff) || user.isAdmin) ? showAdmin() : showUser()}
      </Box>
    </Box>
  );
};

export default Time;
