import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import loadApi from "../api/loadApi";
import boardApi from "../api/boardApi";
import {
  AppBar,
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  IconButton,
  Container,
  Grid,
  Select,
  Toolbar,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UserMenu from "../components/common/UserMenu";

const Reports = () => {
  const [title, setTitle] = useState("Reports");
  const [loading, setLoading] = useState(false);
  const [boards, setBoards] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortModel, setSortModel] = useState([{ field: 'id', sort: 'asc' }]);
  const [filterModel, setFilterModel] = useState({});
  const [rowCount, setRowCount] = useState(0);

  const user = useSelector((state) => state.user.value);
  const navigate = useNavigate();

  // Redirects to home page if you're not worthy
  useEffect(() => {
    if (
      !user.groups.includes("LM_Admin") &&
      user.email !== "farah.vee@trinity512.com"
    ) {
      navigate("/");
    }
  }, [user, navigate]);

  // filter the boards
  const [selectedBoard, setSelectedBoard] = useState(
    "64d5313cacc6bcc1a7fe67bb",
  );

  const [selectedReport, setSelectedReport] = useState("Loads by Customer");

  const handleBoardChange = (event) => {
    setSelectedBoard(event.target.value);
  };

  const handleReportChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedReport(selectedValue);
  };

  // lets render the board filter (except for Tasks)
  const renderBoardFilter = () => {
    if (!boards) return null;
    return (
      <Grid
        container
        spacing={2}
        sx={{ minWidth: 120, alignItems: "center", my: 1 }}
      >
        <Grid item>
          <Select
            value={selectedReport}
            onChange={handleReportChange}
            displayEmpty
            size="small"
          >
            <MenuItem value="">
              <Typography color={(theme) => theme.palette.grey[500]}>
                Select a report
              </Typography>
            </MenuItem>
            <MenuItem value="Loads by Customer">Loads by Customer</MenuItem>
            <MenuItem value="Loads by Carrier" disabled>
              Loads by Carrier
            </MenuItem>
            <MenuItem value="Loads by Shipper" disabled>
              Loads by Shipper
            </MenuItem>
            <MenuItem value="Loads by Receiver" disabled>
              Loads by Receiver
            </MenuItem>
            <MenuItem value="Loads by Section" disabled>
              Loads by Section
            </MenuItem>
            <MenuItem value="Loads by Mode" disabled>
              Loads by Mode
            </MenuItem>
            <MenuItem value="Loads by Tenant" disabled>
              Loads by Tenant
            </MenuItem>
            <MenuItem value="Loads by Status" disabled>
              Loads by Status
            </MenuItem>
            <MenuItem value="Loads by Order Number" disabled>
              Loads by Order Number
            </MenuItem>
            <MenuItem value="Loads by Commodity Type" disabled>
              Loads by Commodity Type
            </MenuItem>
            <MenuItem value="Loads by Notes" disabled>
              Loads by Notes
            </MenuItem>
            <Divider />
            <MenuItem value="Archived Loads" disabled>
              Archived Loads
            </MenuItem>
            <MenuItem value="Deleted Loads" disabled>
              Deleted Loads
            </MenuItem>
          </Select>
        </Grid>
        {boards.length > 0 && (
          <Grid item>
            <Select
              value={selectedBoard || ""}
              onChange={handleBoardChange}
              displayEmpty
              size="small"
            >
              <MenuItem value="">
                <Typography color={(theme) => theme.palette.grey[500]}>
                  Select a customer
                </Typography>
              </MenuItem>
              {boards
                .filter((board) => board.title !== "Tasks")
                .map((board) => (
                  <MenuItem key={board._id} value={board._id}>
                    {board.title}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        )}
      </Grid>
    );
  };

  // lets get all boards from the server
  useEffect(() => {
    boardApi
      .getAll()
      .then((data) => {
        if (data && Array.isArray(data)) {
          setBoards(data);
        } else {
          throw new Error("Invalid data structure received from server");
        }
      })
      .catch((error) => {
        console.error("Data fetching error:", error);
        setBoards([]);
      });
  }, []);

  // history content
  const HistoryContent = React.memo(({ value, row, loading }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = useCallback(() => {
      setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
      setOpen(false);
    }, []);

    return (
      <div>
        <Button
          variant="text"
          color="primary"
          size="small"
          onClick={handleClickOpen}
        >
          {value?.length}{" "}
          {value?.length === 1 ? "history" : "histories"}
        </Button>
        <Dialog open={open} onClose={handleClose} fullScreen sx={{ m: 1 }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: "absolute",
              right: 25,
              top: 15,
              zIndex: 1,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle sx={{ backgroundColor: "var(--backgroundSecondary)" }}>
            {row.qe_id
              ? `Shipment QE_ID: ${row.qe_id}, Updated at: ${new Date(
                row.updated_at,
              ).toLocaleString()}`
              : null}
          </DialogTitle>
          <DialogContent sx={{ backgroundColor: "var(--backgroundSecondary)" }}>
            <DataGridPro
              rows={value}
              columns={[
                { field: "_id", hide: true },
                { field: "user", headerName: "User", width: 200 },
                {
                  field: "description",
                  headerName: "Description",
                  minWidth: 400,
                  flex: 1,
                },
                {
                  field: "timestamp",
                  headerName: "Timestamp",
                  width: 200,
                  type: "dateTime",
                  valueFormatter: ({ value }) =>
                    new Date(value).toLocaleString(),
                },
              ]}
              autosizeOptions={{
                columns: ["description"],
              }}
              autosizeOnMount
              getRowId={(row) => `${row._id}-${row.timestamp}`}
              getRowHeight={() => "auto"}
              density="compact"
              hideFooter
              disableDensitySelector
              initialState={{
                sorting: {
                  sortModel: [{ field: "timestamp", sort: "desc" }],
                },
                columns: {
                  columnVisibilityModel: {
                    _id: false,
                  },
                },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              sx={{
                backgroundColor: "white",
                border: "none",
                padding: { xs: 1, md: 2 },
              }}
              loading={loading}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  });

  // detail panel
  const DetailPanelContent = React.memo(({ row }) => {
    const entries = useMemo(() => Object.entries(row || {}), [row]);
  
    return (
      <div style={{ width: "100%" }}>
        <Box p={3} sx={{ backgroundColor: "var(--backgroundSecondary)" }}>
          <Typography variant="h6" gutterBottom component="div">
            Shipment Details
          </Typography>
          {entries.map(([key, value]) => {
            if (value === null || value === "") return null; // don't display empty values
  
            let displayValue = value;
            if (key.includes("date") || key.includes("timestamp") || key.includes("alert") || key.includes("updated_at")) {
              displayValue = new Date(value).toLocaleString();
            } else if (key.includes("histories")) {
              displayValue = `${value.length} histories`;
            } else if (key.includes("locked")) {
              displayValue = value ? "Yes" : "No";
            } else if (key.includes("locations")) {
              displayValue = (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                    gap: "1rem",
                    maxWidth: "100vw",
                    overflowX: "auto",
                  }}
                >
                  {value.map((location, index) => (
                    <div
                      key={index}
                      style={{
                        marginBottom: "10px",
                        padding: "1rem",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                        Location {index + 1}:
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        {Object.entries(location).map(([key, value]) => {
                          if (value === null || value === "") return null; // don't display empty values
                          if (key.includes("time")) {
                            value = new Date(value).toLocaleString();
                          } else if (key.includes("address")) {
                            value = `${value.line1}, ${value.city}, ${value.state} ${value.zip}`;
                          } else if (key.includes("contact")) {
                            value = `Name: ${value.name}, Email: ${value.email}, Phone: ${value.phone}`;
                          }

                          return (
                            <Typography
                              variant="body2"
                              gutterBottom
                              component="div"
                              key={key}
                            >
                              <strong>
                                {key.charAt(0).toUpperCase() + key.slice(1)}:
                              </strong>{" "}
                              {value}
                            </Typography>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              );
            } else if (key.includes("notes")) {
              displayValue = value.split("\n").map((line, index) => (
                <div key={index}>
                  {line}
                  <Divider style={{ margin: "5px 0" }} />
                </div>
              ));
            } else if (key.includes("url")) {
              displayValue = (
                <a href={value} target="_blank" rel="noreferrer">
                  {value}
                </a>
              );
            } else if (typeof value === "object") {
              displayValue = JSON.stringify(value);
            } else if (key.includes("price")) {
              displayValue = `$${value}`;
            } else if (key.includes("archived") || key.includes("deleted")) {
              displayValue = value ? "Yes" : "No";
            }

            return (
              <Typography
                variant="body2"
                gutterBottom
                component="div"
                key={key}
              >
                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong>{" "}
                {displayValue}
              </Typography>
            );
          })}
        </Box>
      </div>
    );
  });

  const getContent = (Component) => {
    return React.memo((params) => <Component {...params} />);
  };

  const getDetailPanelContent = getContent(DetailPanelContent);
  const getHistoryContent = getContent(HistoryContent);
  const getDetailPanelHeight = useCallback(() => "auto", []);

  // grid columns
  const loadsColumns = useMemo(
    () => [
      {
        field: "updated_at",
        headerName: "Updated At",
        type: "dateTime",
        width: 200,
        valueFormatter: ({ value }) => new Date(value).toLocaleString(),
      },
      { field: "id", headerName: "ID", width: 130 },
      { field: "section", headerName: "Section", width: 130 },
      { field: "tenant_id", headerName: "Tenant ID", width: 130 },
      { field: "qe_id", headerName: "QE ID", width: 130 },
      { field: "reference_id", headerName: "Reference ID", width: 130 },
      {
        field: "histories",
        headerName: "History",
        width: 130,
        renderCell: getHistoryContent,
      },
      { field: "mode", headerName: "Mode", width: 130 },
      { field: "customer", headerName: "Customer", width: 130 },
      { field: "shipper", headerName: "Shipper", width: 130 },
      { field: "receiver", headerName: "Receiver", width: 130 },
      { field: "carrier", headerName: "Carrier", width: 130 },
      {
        field: "alert",
        headerName: "Alert",
        width: 200,
        type: "dateTime",
        valueFormatter: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        field: "user_locked",
        headerName: "User Locked",
        width: 130,
        renderCell: ({ value }) => (value ? "Yes" : "No"),
      },
      {
        field: "locations",
        headerName: "Locations",
        width: 130,
        renderCell: ({ value }) => (value ? `${value.length} locations` : ""),
      },
      { field: "position", headerName: "Position" },
      {
        field: "price",
        headerName: "Price",
        width: 130,
        valueFormatter: ({ value }) => (value ? `$${value}` : ""),
      },
      {
        field: "tracking_url",
        headerName: "Tracking URL",
        width: 130,
        renderCell: (params) => (
          <a href={params.value} target="_blank" rel="noopener noreferrer">
            {params.value}
          </a>
        ),
      },
      { field: "pro_number", headerName: "Pro Number", width: 130 },
      { field: "po_number", headerName: "PO Number", width: 130 },
      { field: "export_to_qe", headerName: "Export to QE", width: 130 },
      { field: "order_number", headerName: "Order Number", width: 130 },
      { field: "commodity_type", headerName: "Commodity Type", width: 130 },
      { field: "archived", headerName: "Archived", width: 130 },
      { field: "deleted", headerName: "Deleted", width: 130 },
      { field: "notes", headerName: "Notes", width: 130, flex: 1 },
    ],
    [],
  );

  // lets get all loads from the server
  const fetchGridData = useCallback(async ({ page, pageSize, sort, filterModel }) => {
    console.log('Fetching data...');

    try {
      const sortField = (Array.isArray(sort) && sort.length > 0) ? sort[0].field : 'updated_at';
      const sortOrder = (Array.isArray(sort) && sort.length > 0) ? sort[0].sort : 'asc';

      let filterParams = {};
      if (filterModel && filterModel.items && filterModel.items.length > 0) {
        const statusFilter = filterModel.items.find(item => item.columnField === 'status');
        if (statusFilter && statusFilter.value) {
          filterParams.status = statusFilter.value;
        }
      }

      const response = await loadApi.getAllByBoardIdPaginated(
        selectedBoard, page, pageSize, sortField, sortOrder, filterParams
      );

      console.log('Raw response:', response); // what it is...

      if (response && response.data) {
        console.log('Data fetched:', response.data);
        return {
          rows: response.data.data || [],
          rowCount: response.data.totalCount || 0,
        };
      } else {
        console.error('Unexpected response:', response);
        throw new Error("Invalid response structure");
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return { rows: [], rowCount: 0 };
    }
  }, [selectedBoard]);

  // fetch data
  useEffect(() => {
    setLoading(true);
    fetchGridData({ page, pageSize, sortModel, filterModel })
      .then(({ rows, rowCount }) => {
        setRows(rows);
        setRowCount(rowCount);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setRows([]);
        setRowCount(0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, pageSize, sortModel, filterModel, fetchGridData]);


  // render the grid
  const renderLoadsGrid = ({
    loadsColumns,
    getDetailPanelContent,
    getDetailPanelHeight,
  }) => (
    <DataGridPro
      columns={loadsColumns}
      rows={rows}
      autoHeight
      pagination
      pageSize={pageSize}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      rowCount={rowCount}
      loading={loading}
      density="compact"
      getRowId={(row) => `${row._id}-${row.timestamp}`}
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={getDetailPanelHeight}
      slots={{
        toolbar: GridToolbar,
      }}
      initialState={{
        columns: {
          columnVisibilityModel: {
            _id: false,
            notes: false,
            // export_to_qe: false,
            // order_number: false,
            // commodity_type: false,
            // price: false,
            // tracking_url: false,
            // pro_number: false,
            // user_locked: false,
            // reference_id: false,
            // shipper: false,
            // receiver: false,
            // carrier: false,
            // archived: false,
            // deleted: false,
            // section: false,
            // tenant_id: false,
            // mode: false,
          },
        },
      }}
      state={{
        pagination: {
          paginationModel: {
            pageSize: pageSize,
            page: page,
          },
        },
      }}
      onStateChange={(newState) => {
        setPage(newState.pagination.paginationModel.page);
        setPageSize(newState.pagination.paginationModel.pageSize);
      }}
      sx={{
        backgroundColor: "white",
        border: "none",
        padding: { xs: 1, md: 2 },
      }}
    />
  );

  // render the page
  return (
    <Box width={"100vw"}>
      <AppBar
        position="static"
        color="inherit"
        className="appBar"
        sx={{ maxWidth: "100vw" }}
      >
        <Toolbar>
          <Typography
            component="div"
            sx={{
              fontFamily: "var(--system-ui)",
              fontSize: { xs: "1rem", md: "1.25rem" },
              flexGrow: "1",
              mr: "1rem",
              fontWeight: "bolder",
              whiteSpace: "nowrap",
            }}
          >
            <Typography variant="subtitle2">
              Load Manager Back Office{" "}
            </Typography>
            {title}
          </Typography>
          <Box className="userMenu">
            <UserMenu />
          </Box>
        </Toolbar>
      </AppBar>
      <Container
        maxWidth="false"
        sx={{
          padding: {
            xs: 1,
            md: 2,
            backgroundColor: "var(--backgroundSecondary)",
          },
        }}
      >
        <Grid container spacing={1}></Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            {renderBoardFilter()}
          </Grid>
        </Grid>
        {renderLoadsGrid({
          loadsColumns,
          getDetailPanelContent,
          getDetailPanelHeight,
        })}
      </Container>
    </Box>
  );
};

export default Reports;