import {
  Backdrop,
  Fade,
  IconButton,
  Modal,
  Box,
  TextField,
  FormControl,
  Button,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Alert,
  Snackbar,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import Moment from "moment";
import taskApi from "../../api/taskApi";
import "../../css/custom-editor.css";
import Loading from "./Loading";
import { useSelector } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import { SiteContext } from "../../contexts/site.context";

class Task {
  _id = undefined;
  title = "";
  section = "";
  alert = "";
  notes = "";
  due_date = "";
}

const TaskModal = (props) => {
  const { users } = useContext(SiteContext);

  const boardId = props.boardId;
  const user = useSelector((state) => state.user.value);
  const [task, setTask] = useState(new Task());
  const [alert, setAlert] = useState({ number: "", unit: "" });
  const [loading, setLoading] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  useEffect(() => {
    taskApi.getAll().then((res) => setStatuses(res));
  }, []);

  useEffect(() => {
    if (props.task !== undefined && task._id === undefined) {
      const taskObj = Object.assign(new Task(), props.task);
      if (!taskObj.created_by) taskObj.created_by = user._id;
      setTask(taskObj);
      lockTask();
    } else setTask(new Task());
  }, [props.task]);

  const lockTask = async () => {
    setLoading(true);
    try {
      await taskApi.lock(boardId, props.task.id, {
        user_locked: {
          user_id: user._id,
          name: user.firstname,
          timestamp: new Date(),
        },
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      let msg = "Failed to Lock!";
      if (err.data.error) msg = err.data.error;
      props.onClose(msg);
    }
  };

  const unlockTask = async () => {
    await taskApi.lock(boardId, props.task.id, {
      user_locked: null,
    });
  };

  const closeSnackbar = () => {
    setSnackbar({
      open: false,
      severity: "success",
      message: "",
    });
  };

  const onClose = async (saved) => {
    unlockTask();
    props.onClose(null, saved);
  };

  const deleteTask = async () => {
    if (window.confirm("Are you sure you want to delete task?")) {
      try {
        await taskApi.delete(boardId, task.id);
        props.onDelete(task);
      } catch (err) {
        setSnackbar({
          open: true,
          severity: "error",
          message: "Failed to delete task",
        });
      }
    }
  };

  const updateTitle = async (event) => {
    const taskCopy = { ...task };
    taskCopy.title = event.target.value;
    setTask(taskCopy);
  };

  const updateAlert = (alertCopy) => {
    setAlert(alertCopy);
    let alertTime = Moment();
    alertTime = alertTime.add(alertCopy.number, alertCopy.unit);
    task.alert = alertTime;
    setTask(task);
  };

  const updateNotes = (event) => {
    const taskCopy = { ...task };
    taskCopy.notes = event.target.value;
    setTask(taskCopy);
  };

  const updateStatus = (event) => {
    const newStatus = event.target.value;
    const taskCopy = { ...task };
    taskCopy.old_section = task.section;
    taskCopy.section = newStatus;
    setTask(taskCopy);
  };

  const save = async () => {
    try {
      await taskApi.update(boardId, task.id, task);
      onClose(true);
    } catch (err) {
      let msg = "Failed to Save!";
      if (err.data.error) msg = err.data.error;
      setSnackbar({
        open: true,
        severity: "error",
        message: msg,
      });
    }
  };

  return (
    <Modal
      open={task._id !== undefined}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={5000}
          onClose={closeSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={closeSnackbar}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
        <Fade in={task._id !== undefined}>
          <Box className="modalPrimary">
            {task._id === undefined || loading ? (
              <Box sx={{ marginY: 2 }}>
                <Loading />
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    float: "right",
                    width: "auto",
                  }}
                >
                  <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Box sx={{ fontSize: "1.2em" }}>Task Details</Box>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      value={task.title}
                      onChange={updateTitle}
                      label="Title"
                      variant="standard"
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-input": { padding: 0 },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "unset ",
                        },
                        "& .MuiOutlinedInput-root": {
                          fontSize: "1.5rem",
                          fontWeight: "700",
                        },
                        marginBottom: 1,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      sx={{ marginBottom: 1 }}
                    >
                      <InputLabel>Status</InputLabel>
                      <Select value={task.section} onChange={updateStatus}>
                        {props.sections.map((section) => {
                          return (
                            <MenuItem key={section._id} value={section._id}>
                              {section.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      sx={{ paddingTop: 1 }}
                    >
                      <DatePicker
                        label="Due Date"
                        value={DateTime.fromISO(task.due_date)}
                        onChange={(newValue) => {
                          const copy = { ...task };
                          copy.due_date = newValue;
                          setTask(copy);
                        }}
                        slotProps={{ textField: { size: "small" } }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel>Created By</InputLabel>
                      <Select
                        value={task.created_by}
                        onChange={(event) => {
                          const copy = { ...task };
                          copy.created_by = event.target.value;
                          setTask(copy);
                        }}
                      >
                        {users.map((user) => {
                          return (
                            <MenuItem key={user._id} value={user._id}>
                              {user.firstname} {user.lastname}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      sx={{ marginLeft: "5px" }}
                    >
                      <InputLabel>Assigned To</InputLabel>
                      <Select
                        value={task.assigned_to}
                        onChange={(event) => {
                          const copy = { ...task };
                          copy.assigned_to = event.target.value;
                          setTask(copy);
                        }}
                      >
                        {users.map((user) => {
                          return (
                            <MenuItem key={user._id} value={user._id}>
                              {user.firstname} {user.lastname}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type="number"
                      value={alert.number}
                      onChange={(event) => {
                        const alertCopy = { ...alert };
                        alertCopy.number = event.target.value;
                        updateAlert(alertCopy);
                      }}
                      label="Alert Time"
                      variant="standard"
                      sx={{
                        marginRight: "5px",
                        width: "100%",
                        "& .MuiOutlinedInput-input": { padding: 0 },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "unset ",
                        },
                        "& .MuiOutlinedInput-root": {
                          fontSize: "1.5rem",
                          fontWeight: "700",
                        },
                        marginBottom: 1,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      sx={{ marginLeft: "5px" }}
                    >
                      <InputLabel>Alert Unit</InputLabel>
                      <Select
                        value={alert.unit}
                        label="Alert Unit"
                        variant="standard"
                        onChange={(event) => {
                          const alertCopy = { ...alert };
                          alertCopy.unit = event.target.value;
                          updateAlert(alertCopy);
                        }}
                        sx={{ marginTop: "12px !important" }}
                      >
                        <MenuItem value={""}>None</MenuItem>
                        <MenuItem value={"minutes"}>Minutes</MenuItem>
                        <MenuItem value={"hours"}>Hours</MenuItem>
                        <MenuItem value={"days"}>Days</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <TextField
                      value={task.notes}
                      label="Notes"
                      fullWidth
                      multiline
                      variant="filled"
                      onChange={updateNotes}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      size="small"
                      variant="text"
                      color="error"
                      onClick={deleteTask}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      disableElevation
                      onClick={save}
                    >
                      Save &amp; Close
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Fade>
      </>
    </Modal>
  );
};

export default TaskModal;
