import { Alert, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import authUtils from '../../utils/authUtils';
import Loading from '../common/Loading';
import { setUser } from '../../redux/features/userSlice';
import boardApi from '../../api/boardApi';
import { setBoards } from '../../redux/features/boardSlice';

const AppLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { boardId } = useParams();
  const boards = useSelector((state) => state.board.value);

  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    const user = await authUtils.isAuthenticated();

    setLoading(false);
    if (user) {
      dispatch(setUser(user));

      const boards = await boardApi.getAll(boardId ? boardId : null);
      if (boards.length === 0) {
        setErrorMsg('No Shipment Boards Found!');
      } else {
        dispatch(setBoards(boards));
      }
    } else {
      setErrorMsg('Failed to Log In!');
    }
  };

  if (loading) {
    return <Loading fullHeight />;
  } else if (errorMsg) {
    return (
      <Alert severity="error" sx={{ margin: 5 }}>
        {errorMsg}
      </Alert>
    );
  } else {
    return (
      <Box
        sx={{
          display: 'flex',
        }}
      >
        {/* <Sidebar /> */}
        <Box
          sx={{
            flexGrow: 1,
            width: 'max-content',
          }}
        >
          <Outlet />
        </Box>
      </Box>
    );
  }
};

export default AppLayout;
