import axiosClient from "./axiosClient";

const timeApi = {
  create: (params) => axiosClient.post("time", params),
  getAll: (params) => axiosClient.get("time", { params }),
  getAllByUser: (userId) => axiosClient.get(`time/${userId}`),
  delete: (id) => axiosClient.delete(`time/${id}`),
  approve: (id) => axiosClient.get(`time/approve/${id}`),
};

export default timeApi;
