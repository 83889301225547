import React, { useEffect, useState } from "react";
import userApi from "../api/userApi";

const SiteContext = React.createContext();

const SiteProvider = (props) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    userApi.getAll().then((users) => setUsers(users));
  }, []);

  return (
    <SiteContext.Provider
      value={{
        users,
      }}
    >
      {props.children}
    </SiteContext.Provider>
  );
};

export { SiteContext, SiteProvider };
