import axiosClient from "./axiosClient";

const loadApi = {
  getAll: (boardId) => axiosClient.get(`boards/${boardId}/loads`),
  getAllByBoardIdPaginated: (boardId, params) =>
    axiosClient.get(`boards/${boardId}/loads/paginated`, { params }),
  create: (boardId, params) =>
    axiosClient.post(`boards/${boardId}/loads`, params),
  updatePosition: (boardId, params) =>
    axiosClient.put(`boards/${boardId}/loads/update-position`, params),
  delete: (boardId, loadId) =>
    axiosClient.delete(`boards/${boardId}/loads/${loadId}`),
  update: (boardId, loadId, params) =>
    axiosClient.put(`boards/${boardId}/loads/${loadId}`, params),
  lock: (boardId, loadId, params) =>
    axiosClient.put(`boards/${boardId}/loads/lock/${loadId}`, params),
  uploadLoadFile: (params) =>
    axiosClient.post('loads/upload-file', params, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
};

export default loadApi;
