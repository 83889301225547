import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ViewKanbanOutlinedIcon from "@mui/icons-material/ViewKanbanOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import TaskIcon from "@mui/icons-material/Task";
import SupervisedUserCircleSharpIcon from "@mui/icons-material/SupervisedUserCircleSharp";
import InventorySharpIcon from "@mui/icons-material/InventorySharp";
import { msalInstance } from "../..";

const UserMenu = (props) => {
  const boards = useSelector((state) => state.board.value);

  const user = useSelector((state) => state.user.value);
  const [anchorEl, setAnchorEl] = useState(null);

  const logout = () => {
    const accounts = msalInstance.getAllAccounts();
    msalInstance.logoutRedirect({ account: accounts[0] });
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {boards
          .filter((board) => !board.type || board.type === "load")
          .map((tenant, index) => (
            <MenuItem
              key={"tenant" + index}
              component={Link}
              reloadDocument
              to={"/boards/" + tenant.id}
            >
              <ListItemIcon>
                <ViewKanbanOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{tenant.title}</ListItemText>
            </MenuItem>
          ))}
        <Divider />
        {boards
          .filter((board) => board.type === "task")
          .map((tenant, index) => (
            <MenuItem
              key={"tenant" + index}
              component={Link}
              reloadDocument
              to={"/boards/" + tenant.id}
            >
              <ListItemIcon>
                <TaskIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{tenant.title}</ListItemText>
            </MenuItem>
          ))}
        <Divider />
        {(user.rate || user.isAdmin) && (
          <MenuItem component={Link} to="/time">
            <ListItemIcon>
              <AccessTimeOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Time</ListItemText>
          </MenuItem>
        )}
        {user.isAdmin && (
          <MenuItem component={Link} to="/users">
            <ListItemIcon>
              <GroupOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Users</ListItemText>
          </MenuItem>
        )}
        {(user.groups.includes("LM_Admin") ||
          ["farah@trinity512.com", "farah.vee@trinity512.com"].includes(
            user.email,
          )) && [
          <MenuItem key="user-activity" component={Link} to="/user-activity">
            <ListItemIcon>
              <SupervisedUserCircleSharpIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>User Activity</ListItemText>
          </MenuItem>
          // <MenuItem key="reports" component={Link} to="/reports">
          //   <ListItemIcon>
          //     <InventorySharpIcon fontSize="small" />
          //   </ListItemIcon>
          //   <ListItemText>Reports</ListItemText>
          // </MenuItem>
        ]}
        {(user.isAdmin || user.isStaff) && (
          <MenuItem component={Link} to="/companies">
            <ListItemIcon>
              <StoreOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Companies</ListItemText>
          </MenuItem>
        )}
        <MenuItem component={Link} to="/account">
          <ListItemIcon>
            <AccountCircleOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Account</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <ExitToAppOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
