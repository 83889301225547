import {
  Box,
  TextField,
  IconButton,
  Card,
  Chip,
  Tooltip,
  Alert,
  Snackbar,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import sectionApi from '../../api/sectionApi';
import LoadModal from './LoadModal';
import Countdown from 'react-countdown';
import Moment from 'moment';
import Loading from './Loading';
import { useSelector } from 'react-redux';
import TaskModal from './TaskModal';
import { BoardType } from '../../constants/boardTypes';

let timer;
const timeout = 500;

const companyOrder = [
  {
    type: 'customer',
  },
];

const Sections = (props) => {
  const boardId = props.boardId;

  const companies = useSelector((state) => state.companies.value);
  const user = useSelector((state) => state.user.value);

  const [data, setData] = useState([]);
  const [companiesByType, setCompaniesByType] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    message: '',
  });

  useEffect(() => {
    if (companies.length > 0) {
      getCompanies();
    }

    if (sections.length === 0) getSections();

    const sectionItems = props.sections.map((section) => {
      section.items = props.items
        .filter((item) => item.section === section._id)
        .sort((a, b) => b.position - a.position);
      return section;
    });
    sortItems(sectionItems);
  }, [props.section, props.items, companies]);

  useEffect(() => {
    if (props.context.editItem) setSelectedItem(props.context.editItem);
  }, [props.context.editItem]);

  useEffect(() => {
    if (!selectedItem) props.context.setEditItem(undefined);
  }, [selectedItem]);

  const getSections = async () => {
    const res = await sectionApi.getAll(props.boardId);
    setSections(res);
  };

  const getCompanies = async () => {
    try {
      const companiesById = {};
      companies.map((company) => {
        companiesById[company._id] = company;
      });
      setCompaniesByType(companiesById);
    } catch (err) {
      alert(err);
    }
  };

  const onDragEnd = async (params) => {
    const { source, destination, draggableId } = params;

    if (!destination) return;
    const sourceColIndex = data.findIndex((e) => e.id === source.droppableId);
    const destinationColIndex = data.findIndex(
      (e) => e.id === destination.droppableId,
    );
    const sourceCol = data[sourceColIndex];
    const destinationCol = data[destinationColIndex];

    const sourceLoads = [...sourceCol.items];
    const destinationLoads = [...destinationCol.items];

    if (source.droppableId !== destination.droppableId) {
      const [removed] = sourceLoads.splice(source.index, 1);
      destinationLoads.push(removed);
      data[sourceColIndex].items = sourceLoads;
      data[destinationColIndex].items = destinationLoads;
    }

    const item = props.context.item.find((item) => item.id === draggableId);
    if (!item.user_locked) {
      item.section = destination.droppableId;
      setSelectedItem(item);
      sortItems(data);
    }
  };

  const updateSectionTitle = async (e, sectionId) => {
    clearTimeout(timer);
    const newTitle = e.target.value;
    const newData = [...data];
    const index = newData.findIndex((e) => e.id === sectionId);
    newData[index].title = newTitle;
    setData(newData);
    timer = setTimeout(async () => {
      try {
        await sectionApi.update(boardId, sectionId, { title: newTitle });
      } catch (err) {
        alert(err);
      }
    }, timeout);
  };

  const createItem = async (sectionId) => {
    try {
      const item = await props.api.create(boardId, { sectionId });
      const newData = [...data];
      const index = newData.findIndex((e) => e.id === sectionId);
      newData[index].items.unshift(item);
      sortItems(newData);
    } catch (err) {
      alert(err);
    }
  };

  const onDeleteLoad = () => {
    setSelectedItem(undefined);
  };

  const disableAlert = async (item) => {
    const newData = [...data];
    const sectionIndex = newData.findIndex((e) => e.id === item.section);
    const itemIndex = newData[sectionIndex].items.findIndex(
      (e) => e.id === item.id,
    );
    newData[sectionIndex].items[itemIndex].alert = null;
    sortItems(newData);
    try {
      await props.api.update(boardId, item.id, { alert: null });
    } catch (err) {
      alert(err);
    }
  };

  const sortItems = (dataCopy) => {
    dataCopy = dataCopy.map((section) => {
      section.items = props.context.sort(section.items);
      return section;
    });
    setData(dataCopy);
    setLoading(false);
  };

  const closeSnackbar = () => {
    setSnackbar({
      open: false,
      severity: 'success',
      message: '',
    });
  };

  const renderCountdown = ({ completed, props, formatted }) => {
    if (completed) {
      // Render a completed state
      return (
        <Chip
          className="cardChip"
          label="Needs Attention"
          variant="outlined"
          color="error"
          size="small"
          disabled={props.item.user_locked && props.item.user_locked !== null}
          onDelete={() => {
            disableAlert(props.item);
          }}
        />
      );
    } else {
      // Render a countdown
      return (
        <Chip
          label={
            formatted.days +
            ':' +
            formatted.hours +
            ':' +
            formatted.minutes +
            ':' +
            formatted.seconds
          }
          className="cardChip"
          variant="outlined"
          color="success"
          size="small"
          disabled={props.item.user_locked && props.item.user_locked !== null}
          onDelete={() => {
            disableAlert(props.item);
          }}
        />
      );
    }
  };

  const renderCardTask = (item, index) => {
    return (
      <Box>
        {item.title ? item.title : 'New Task'}
        {item.notes && (
          <Tooltip
            title={<span style={{ whiteSpace: 'pre-line' }}>{item.notes}</span>}
          >
            <ModeCommentOutlinedIcon className="cardCommentIcon" />
          </Tooltip>
        )}
        {item.user_locked && (
          <Tooltip
            title={
              item.user_locked.name +
              ' @ ' +
              Moment(item.user_locked.timestamp).format('h:mm a')
            }
          >
            <LockOutlinedIcon className="cardLockIcon" />
          </Tooltip>
        )}
        {item.due_date && (
          <Box>Due: {Moment(item.due_date).format('MM/DD/YY')}</Box>
        )}
        {item.alert && (
          <Box>
            <Countdown
              item={item}
              date={item.alert}
              renderer={renderCountdown}
            />
          </Box>
        )}
      </Box>
    );
  };

  const renderCardLoad = (item, index) => {
    return (
      <Box>
        {user.isCustomer && <>{item.po_number ? item.po_number : 'PO #'}</>}
        {!user.isCustomer && <>{item.qe_id ? item.qe_id : 'QE ID'}</>}
        {item.notes && (
          <Tooltip
            title={<span style={{ whiteSpace: 'pre-line' }}>{item.notes}</span>}
          >
            <ModeCommentOutlinedIcon className="cardCommentIcon" />
          </Tooltip>
        )}
        {item.user_locked && (
          <Tooltip
            title={
              item.user_locked.name +
              ' @ ' +
              Moment(item.user_locked.timestamp).format('h:mm a')
            }
          >
            <LockOutlinedIcon className="cardLockIcon" />
          </Tooltip>
        )}
        <Box className="CardCompanyNameWrap">
          {companyOrder.map((type, index) => {
            return (
              <Box className="cardCompanyName" key={index}>
                {companiesByType && companiesByType[item[type.type]]
                  ? companiesByType[item[type.type]].name
                  : 'N/A'}
              </Box>
            );
          })}
        </Box>
        {item.alert && (
          <Countdown item={item} date={item.alert} renderer={renderCountdown} />
        )}
      </Box>
    );
  };

  const renderCard = (section, item, index) => {
    return (
      <Draggable
        key={item.id}
        draggableId={item.id}
        index={index}
        // isDragDisabled={
        //   item.user_locked !== null ||
        //   (user.isCustomer && section.customer_access === 'read')
        // }
        isDragDisabled={true}
      >
        {(provided, snapshot) => (
          <Card
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="card"
            sx={{
              ':hover': {
                boxShadow: 2, // theme.shadows[2]
              },
              opacity: item.user_locked ? '.7' : '1',
              cursor: item.user_locked
                ? 'default'
                : snapshot.isDragging
                ? 'grab'
                : 'pointer!important',
              borderStyle: item.user_locked ? 'dashed' : 'inherit',
              borderColor: item.user_locked ? '#ccc' : 'inherit',
            }}
            onClick={() => {
              if (!item.user_locked) {
                setSelectedItem(item);
              }
            }}
          >
            {props.boardType === BoardType.Task
              ? renderCardTask(item, index)
              : renderCardLoad(item, index)}
          </Card>
        )}
      </Draggable>
    );
  };

  const renderGrid = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Box
          sx={{
            // display: "flex",
            // flexGrow: "1",
            width: '100%',
            height: '100%',
          }}
        >
          <Box className="boardContainer">
            {data.map((section) => (
              <div key={section.id} className="boardColumnWrap">
                <Droppable key={section.id} droppableId={section.id} isDropDisabled={true} isDragDisabled={true}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="boardColumn"
                    >
                      <Box
                        className="boardColumnHeader"
                        sx={{ height: '36px' }}
                      >
                        {user.isAdmin && (
                          <TextField
                            value={section.title}
                            onChange={(e) => updateSectionTitle(e, section.id)}
                            placeholder="Untitled"
                            variant="outlined"
                            className="boardColumnTitle"
                          />
                        )}
                        {!user.isAdmin && (
                          <Box className="boardColumnTitle">
                            {section.title}
                          </Box>
                        )}
                        <Box className="boardColumnLength">
                          ({section.items.length})
                        </Box>
                        {(!user.isCustomer ||
                          section.customer_access === 'write' ||
                          section.customer_access === 'delete') && (
                          <IconButton
                            variant="outlined"
                            size="small"
                            onClick={() => createItem(section.id)}
                          >
                            <AddOutlinedIcon />
                          </IconButton>
                        )}
                      </Box>
                      {section.items.map((item, index) =>
                        renderCard(section, item, index),
                      )}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </div>
            ))}
          </Box>
        </Box>
      </DragDropContext>
    );
  };

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      {loading ? <Loading fullHeight /> : <>{renderGrid()}</>}
      {props.boardType === BoardType.Task ? (
        <TaskModal
          task={selectedItem}
          sections={sections}
          boardId={boardId}
          onClose={(err, saved) => {
            if (err) {
              setSnackbar({
                open: true,
                severity: 'error',
                message: err,
              });
            }
            if (!saved) {
            }
            setSelectedItem(undefined);
          }}
          onDelete={onDeleteLoad}
        />
      ) : (
        <LoadModal
          load={selectedItem}
          sections={sections}
          boardId={boardId}
          onClose={(err, saved) => {
            if (err) {
              setSnackbar({
                open: true,
                severity: 'error',
                message: err,
              });
            }
            if (!saved) {
            }
            setSelectedItem(undefined);
          }}
          onDelete={onDeleteLoad}
        />
      )}
    </>
  );
};

export default Sections;
