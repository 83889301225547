import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  Snackbar,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  ListItemText,
  Checkbox,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Container,
  Rating,
  Divider,
} from '@mui/material';
import companyApi from '../api/companyApi';
import laneApi from '../api/laneApi';
import UserMenu from '../components/common/UserMenu';
import EditIcon from '@mui/icons-material/Edit';
import companyTypes from '../constants/companyTypes';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import CircularProgress from '@mui/material/CircularProgress';

const RenderTypeSelector = React.memo(
  ({ allTypes, selectedTypes, setSelectedTypes }) => {
    const handleTypeFilterChange = (event) => {
      const {
        target: { value },
      } = event;
      setSelectedTypes(typeof value === 'string' ? value.split(',') : value);
    };

    return (
      <Box>
        <FormControl sx={{ m: 1, width: 300 }} size="small">
          <InputLabel id="company-type-label">Type</InputLabel>
          <Select
            multiple
            label="Type"
            labelId="company-type-label"
            value={selectedTypes}
            onChange={handleTypeFilterChange}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 'auto',
                },
              },
            }}
          >
            {allTypes.map((type) => (
              <MenuItem key={type} value={type}>
                <Checkbox checked={selectedTypes.indexOf(type) > -1} />
                <ListItemText primary={type} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  },
);

const SelectInput = React.memo(({ label, value = [], options = [], onChange }) => {
  const handleChange = useCallback(
    (event) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
<FormControl fullWidth size={inputSize}>
  <InputLabel labelId={`select-label-for-${label}`}>{label}</InputLabel>
  <Select
    multiple
    labelId={`select-label-for-${label}`}
    label={label}
    options={options}
    value={value}
    onChange={handleChange}
    inputProps={{ 'aria-label': label }}
    size={inputSize}
    renderValue={(selected = []) =>
      label === 'Lanes' ? (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {selected.map((value) => (
            <Chip
              key={value}
              label={value}
              sx={{ marginRight: 0.5, marginBottom: 0.5 }}
              size="small"
            />
          ))}
        </Box>
      ) : (
        selected.join(', ')
      )
    }
  >
    {options.map((option) => (
      <MenuItem key={option} value={option}>
        <Checkbox checked={value.indexOf(option) > -1} />
        <ListItemText primary={option} />
      </MenuItem>
    ))}
  </Select>
</FormControl>
  );
});

const initialSnackbar = {
  open: false,
  severity: 'success',
  message: '',
};

const isMobile = window.innerWidth <= 768;
const inputSize = isMobile ? 'large' : 'small';

const AddCompanyButton = ({ onAdd }) => (
  <Button
    variant="contained"
    color="primary"
    disableElevation
    size="small"
    sx={{marginRight: 2}}
    onClick={() =>
      onAdd({ name: '', email: '', phone: '', types: [], lanes: [] })
    }
  >
    Add Company
  </Button>
);

const Companies = () => {
  const [title, setTitle] = useState('Companies');
  const [lanes, setLanes] = useState([]);
  const [allList, setAllList] = useState([]);
  const [editItem, setEditItem] = useState(undefined);
  const [snackbar, setSnackbar] = useState(initialSnackbar);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedLanes, setSelectedLanes] = useState([]);

  const isMobile = window.innerWidth <= 768;

  const [isLoading, setIsLoading] = useState(false);

  const allTypes = useMemo(() => Object.values(companyTypes), []);

  // useEffect(() => {
  //   const fetchLanes = async () => {
  //     try {
  //       const res = await laneApi.getAll();
  //       console.log('Lanes:', res);
  //       setLanes(res.data);
  //     } catch (error) {
  //       console.error('Error fetching lanes:', error);
  //     }
  //   };
  //   fetchLanes();
  // }, []);

  useEffect(() => {
    if (editItem) {
      laneApi.getAll().then((lanes) => {
        setLanes(lanes);
      });
    }
  }, [editItem]);

  const getList = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await companyApi.getAll();
      setAllList(res);
    } catch (error) {
      console.error('Error fetching list:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    let isSubscribed = true; // prevent mem leak
    if (isSubscribed) {
      getList();
    }
    return () => {
      isSubscribed = false;
    };
  }, [getList]);

  const handleCloseDialog = () => {
    setSelectedLanes([]);
    setEditItem(undefined);
  };

  const closeSnackbar = () => {
    setSnackbar({
      open: false,
      severity: 'success',
      message: '',
    });
  };

  const getFilteredRows = useMemo(() => {
    return selectedTypes.length === 0
      ? allList
      : allList.filter((row) =>
          selectedTypes.every((type) => row.types.includes(type)),
        );
  }, [allList, selectedTypes]);

  const RenderTable = () => {
    const columns = useMemo(
      () => [
        { field: 'name', headerName: 'Name', minWidth: 300, flex: 1 },
        {
          field: 'types',
          headerName: 'Type(s)',
          width: 150,
          sortComparator: (v1, v2, cellParams1, cellParams2) => {
            const types1 = v1.join(', ');
            const types2 = v2.join(', ');
            return types1.localeCompare(types2);
          },
        },
        {
          field: 'lanes',
          headerName: 'Lanes',
          width: 150,
          renderCell: (params) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {params.value ? params.value.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  sx={{ marginRight: 0.5, marginBottom: 0.5 }}
                  size="small"
                />
              )) : null}
            </Box>
          ),
        },
        { field: 'email', headerName: 'Email', width: 300 },
        { field: 'phone', headerName: 'Phone', width: 150 },
        { field: 'qe_id', headerName: 'Trinity Customer ID', width: 200 },
        {
          field: 'rating',
          headerName: 'Rating',
          width: 100,
          renderCell: (params) => (
            <Rating
              name="company rating"
              value={params.value}
              precision={1}
              max={4}
              size="small"
              sx={{ color: 'primary.main' }}
              readOnly
            />
          ),
        },
        {
          field: 'actions',
          headerName: 'Actions',
          width: 100,
          renderCell: (params) => (
            <IconButton
              size="small"
              onClick={() => {
                const item = params.row;
                const itemCopy = JSON.parse(JSON.stringify(item));
                setEditItem(itemCopy);
              }}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          ),
        },
      ],
      [],
    );

    return (
      <>
        {getFilteredRows && (
          <DataGridPro
            rows={getFilteredRows}
            columns={columns}
            autoHeight
            loading={isLoading}
            pagination
            disableDensitySelector
            density={isMobile ? 'standard' : 'compact'}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }],
              },
            }}
            sx={{
              border: 'none',
              padding: { xs: 1, md: 2 },
              marginTop: 1,
              backgroundColor: 'white',
            }}
          />
        )}
      </>
    );
  };

  const handleSave = useCallback(async () => {
    setIsLoading(true);
    try {
      const itemToSave = {
        ...editItem,
        lanes: selectedLanes.map(lane => lane._id),
      };

      if (itemToSave._id) {
        // update
        await companyApi.update(itemToSave._id, itemToSave);
        setSnackbar({
          open: true,
          severity: 'success',
          message: 'Saved!',
        });
      } else {
        await companyApi.create(itemToSave);
      }
      await getList();
    } catch (error) {
      console.error('Error saving item:', error);
      setSnackbar({
        open: true,
        severity: 'error',
        message: `Error saving item: ${error.message}`,
      });
    } finally {
      setIsLoading(false);
      setSelectedLanes([]);
      setEditItem(undefined);
    }
  }, [selectedLanes, editItem, getList]);

  const deleteEditItem = useCallback(async () => {
    if (window.confirm('Are you sure you want to delete?')) {
      try {
        await companyApi.delete(editItem._id);
        await getList();
        setEditItem(undefined);
        setSnackbar({
          open: true,
          severity: 'success',
          message: 'Deleted!',
        });
      } catch (error) {
        console.error('Error deleting item:', error);
        setSnackbar({
          open: true,
          severity: 'error',
          message: `Error deleting item: ${
            error.response.statusText || error.message
          }`,
        });
      }
    }
  }, [editItem, getList]);

  const renderEdit = useCallback(() => {
    
    if (!editItem) return null;
    
    const handleInputChange = (field) => (eventOrValue) => {
      let value;

      if (Array.isArray(eventOrValue)) {
        value = eventOrValue;
      } else if (typeof eventOrValue === 'object' && eventOrValue.target) {
        value = eventOrValue.target.value;
      } else {
        value = eventOrValue;
      }

      setEditItem((prevEditItem) => ({ ...prevEditItem, [field]: value }));
    };

    return (
      <Dialog open={!!editItem} onClose={handleCloseDialog}>
        <DialogTitle>
          {editItem && editItem._id
            ? `Edit Company: ${editItem.name}`
            : 'Add New Company'}
        </DialogTitle>
        <DialogContent sx={{ padding: 3 }}>
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label="Name"
                size={inputSize}
                value={editItem.name || ''}
                onChange={handleInputChange('name')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Trinity Customer ID"
                size={inputSize}
                value={editItem.qe_id || ''}
                onChange={handleInputChange('qe_id')}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <FormControl fullWidth size={inputSize}>
                <InputLabel>Rating</InputLabel>
                <Select
                  value={editItem.rating || ''}
                  onChange={(event) =>
                    handleInputChange('rating')(event.target.value)
                  }
                >
                  {[1, 2, 3, 4].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <SelectInput
                multiple
                label="Types"
                size={inputSize}
                options={companyTypes}
                value={editItem.types || []}
                onChange={handleInputChange('types')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                size={inputSize}
                value={editItem.email || ''}
                onChange={handleInputChange('email')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Phone"
                size={inputSize}
                value={editItem.phone || ''}
                onChange={handleInputChange('phone')}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                multiple
                label="Lanes"
                size={inputSize}
                options={lanes ? lanes.map(lane => lane.lane) : []}
                value={editItem.lanes || []}
                onChange={handleInputChange('lanes')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'block',
            padding: 3,
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          {editItem && editItem._id && (
            <Button
              size="small"
              variant="outlined"
              color="error"
              sx={{ float: 'left' }}
              onClick={deleteEditItem}
            >
              Delete
            </Button>
          )}
          <Button
            size="small"
            variant="contained"
            color="primary"
            disableElevation
            sx={{ float: 'right' }}
            onClick={handleSave}
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size={24} /> : null}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            sx={{ float: 'right', marginRight: 1 }}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }, [deleteEditItem, editItem, handleSave, isLoading, lanes]);

  return (
    <Box width={'100vw'}>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={2000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <AppBar
        position="static"
        color="inherit"
        className="appBar"
        sx={{ maxWidth: '100vw' }}
      >
        <Toolbar>
          <Typography
            component="div"
            sx={{
              fontFamily: 'var(--system-ui)',
              fontSize: { xs: '1rem', md: '1.25rem' },
              flexGrow: '1',
              mr: '1rem',
              fontWeight: 'bolder',
              whiteSpace: 'nowrap',
            }}
          >
            <Typography variant="subtitle2">
              Load Manager Back Office{' '}
            </Typography>
            {title}
          </Typography>
          <Box className="userMenu">
            <UserMenu />
          </Box>
        </Toolbar>
      </AppBar>
      <Container
        maxWidth="false"
        sx={{
          padding: {
            xs: 1,
            md: 2,
            backgroundColor: 'var(--backgroundSecondary)',
          },
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <RenderTypeSelector
              {...{ allTypes, selectedTypes, setSelectedTypes }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              justifyContent: { md: 'flex-end' },
              alignItems: 'center',
            }}
          >
            <AddCompanyButton onAdd={setEditItem} />
          </Grid>
        </Grid>
        <RenderTable />
        {editItem && renderEdit()}
      </Container>
    </Box>
  );
};

export default Companies;
