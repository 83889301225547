const { Popover } = require("@mui/material")

export const CustomPopover = (props) => {

    const {
        id,
        handleClose,
        anchorEl,
        vertical = 'bottom',
        horizontal = 'left',
        children,
        ...others
    } = props

    return <Popover
        id={id}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: vertical,
            horizontal: horizontal
        }}
        {...others}
    >
        {children}
    </Popover>;

}