import axiosClient from './axiosClient';

const laneApi = {
  getAll: () => axiosClient.get('lanes'),
  // create: (params) => axiosClient.post('lanes', params),
  // update: (id, params) => axiosClient.put(`lanes/${id}`, params),
  // delete: (id) => axiosClient.delete(`lanes/${id}`),
};

export default laneApi;
