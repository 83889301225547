import { createSlice } from '@reduxjs/toolkit'
import accessUtils from '../../utils/accessUtils';

const initialState = { value: {} }

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      const user = action.payload;
      user.isCustomer = true;
      user.isStaff = false;
      user.isAdmin = false;
      if (user.groups) {
        user.isCustomer = accessUtils.isCustomer(user);
        user.isStaff = accessUtils.isStaff(user);
        user.isAdmin = accessUtils.isAdmin(user);
      }
      state.value = user;
    }
  }
})

export const { setUser } = userSlice.actions

export default userSlice.reducer