import axiosClient from './axiosClient';

const userApi = {
  getAll: () => axiosClient.get('users'),
  create: (user) => axiosClient.post('users', user),
  update: (user) => axiosClient.put('users', user),
  delete: (user) => axiosClient.post('users/delete', user),
};

export default userApi;
