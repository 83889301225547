import axiosClient from "./axiosClient";

const taskApi = {
  getAll: () => axiosClient.get("tasks"),
  getStatuses: () => axiosClient.get("tasks/statuses"),
  create: (boardId, params) =>
    axiosClient.post(`boards/${boardId}/tasks`, params),
  delete: (boardId, taskId) =>
    axiosClient.delete(`boards/${boardId}/tasks/${taskId}`),
  update: (boardId, taskId, params) =>
    axiosClient.put(`boards/${boardId}/tasks/${taskId}`, params),
  lock: (boardId, taskId, params) =>
    axiosClient.put(`boards/${boardId}/tasks/lock/${taskId}`, params),
};

export default taskApi;
