import axiosClient from './axiosClient';

const companyApi = {
  getAll: () => axiosClient.get('companies'),
  create: (params) => axiosClient.post('companies', params),
  update: (id, params) => axiosClient.put(`companies/${id}`, params),
  delete: (id) => axiosClient.delete(`companies/${id}`),
};

export default companyApi;
