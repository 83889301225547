import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: [] };

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompanies: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setCompanies } = companySlice.actions;

export default companySlice.reducer;
