const accessUtils = {
  isCustomer: (user) => {
    return (
      !user.groups ||
      (user.groups && user.groups.indexOf('LM_Guest') >= 0) ||
      (user.groups.indexOf('LM_Staff') < 0 &&
        user.groups.indexOf('LM_Admin') < 0)
    );
  },
  isStaff: (user) => {
    return user.groups && user.groups.indexOf('LM_Staff') >= 0;
  },
  isAdmin: (user) => {
    return user.groups && user.groups.indexOf('LM_Admin') >= 0;
  },
};

export default accessUtils;
