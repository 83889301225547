import { Alert, Backdrop, Box, Button, Fade, Grid, IconButton, Modal, Snackbar, TextField } from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import timeApi from "../../api/timeApi"

const UserTimeModal = ({ time, onClose, onTimeSaved, onTimeDeleted, isOpen }) => {
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: "success",
        message: "",
    });

    const closeSnackbar = () => {
        setSnackbar({
            open: false,
            severity: "success",
            message: "",
        });
    };

    const getTotal = () => {
        let total = 0;
        total += time.hours * time.user.rate.hourly;
        total += time.holiday_hours * time.user.rate.holiday_hourly;
        total += time.overtime_hours * time.user.rate.overtime_hourly;
        return Math.ceil(total * 10) / 10;
    };

    const submit = async () => {
        const updatedTime = {
            _id: time._id,
            hours: time.hours,
            holiday_hours: time.holiday_hours,
            overtime_hours: time.overtime_hours,
            total: getTotal(),
        };

        setSnackbar({
            open: true,
            severity: "info",
            message: "Saving!",
        });
        try {
            await timeApi.create(updatedTime);
            setSnackbar({
                open: true,
                severity: "success",
                message: "Saved!",
            });
            closeSnackbar();
            onTimeSaved();
        } catch (err) {
            setSnackbar({
                open: true,
                severity: "error",
                message: err.data.error,
            });
        }
    };

    const remove = async () => {
        setSnackbar({
            open: true,
            severity: "info",
            message: "Deleting!"
        });
        try {
            await timeApi.delete(time._id);
            setSnackbar({
                open: true,
                severity: "success",
                message: "Deleted!",
            });
            closeSnackbar();
            onTimeDeleted();
        } catch (err) {
            setSnackbar({
                open: true,
                severity: "error",
                message: err.data.error,
            });
        }
    }

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <>
                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={5000}
                    onClose={closeSnackbar}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <Alert
                        onClose={closeSnackbar}
                        severity={snackbar.severity}
                        sx={{ width: "100%" }}
                    >
                        {snackbar.message}
                    </Alert>
                </Snackbar>
                <Fade in={isOpen}>
                    <Box className="modalPrimary" maxWidth={820}>
                        <Box
                            sx={{
                                float: "left",
                                width: "auto",
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    display='flex'
                                    justifyContent="space-between"
                                    width='100%'
                                >
                                    <Box sx={{ fontSize: "1.2em", marginTop: 0.25 }}>{`${time?.user?.firstname} ${time?.user?.lastname ?? ''}`}</Box>
                                    <IconButton
                                        aria-label="close"
                                        onClick={onClose}
                                        sx={{
                                            color: (theme) => theme.palette.grey[500],
                                            float: 'right'
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
                                    <TextField
                                        sx={{ marginRight: 2 }}
                                        fullWidth
                                        defaultValue={time?.hours}
                                        variant="outlined"
                                        label="Hours"
                                        type="number"
                                        onChange={(event) => time.hours = event.target.value}
                                    />
                                    <TextField
                                        sx={{ marginRight: 2 }}
                                        fullWidth
                                        defaultValue={time?.overtime_hours}
                                        variant="outlined"
                                        label="Overtime Hours"
                                        type="number"
                                        onChange={(event) => time.overtime_hours = event.target.value}
                                    />
                                    <TextField
                                        fullWidth
                                        defaultValue={time?.holiday_hours}
                                        variant="outlined"
                                        label="Holiday Hours"
                                        type="number"
                                        onChange={(event) => time.holiday_hours = event.target.value}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        mt: 2,
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Button
                                        size="small"
                                        variant="text"
                                        color="error"
                                        onClick={remove}
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        disableElevation
                                        onClick={submit}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                </Fade>
            </>
        </Modal>
    );
}

export default UserTimeModal;