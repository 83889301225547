import axiosClient from "./axiosClient";

const authApi = {
  signup: (params) => axiosClient.post("auth/signup", params),
  login: (params) => axiosClient.post("auth/login", params),
  verifyToken: () => axiosClient.post("auth/verify-token"),
  get: () => axiosClient.get("auth"),
  stripeOnboard: () => axiosClient.get("auth/stripe_onboard"),
};

export default authApi;
