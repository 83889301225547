import axios from "axios";
import queryString from "query-string";
import { config } from "../config";
import { InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { msalInstance } from "../index";

const baseUrl = config.api + config.apiRoutes;

export const getToken = async () => {
  const accounts = msalInstance.getAllAccounts();

  if (accounts.length > 0) {
    try {
      const res = await msalInstance.acquireTokenSilent({
          scopes: config.sso.scopes,
          account: accounts[0]
      })

      return res.accessToken;
    } catch(error) {
      console.log(error);
      if (error instanceof InteractionRequiredAuthError) {
          await msalInstance.loginRedirect({scopes: config.sso.scopes})
      }
    }
  } else {
    await msalInstance.loginRedirect({scopes: config.sso.scopes})
  }
}

const axiosClient = axios.create({
  baseURL: baseUrl,
  // paramsSerializer: (params) => queryString.stringify({ params }),
});

axiosClient.interceptors.request.use(async (config) => {
  return {
    ...config,
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${await getToken()}`,
    },
  };
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) return response.data;
    return response;
  },
  (err) => {
    if (!err.response) {
      return alert(err.message);
    }
    throw err.response;
  }
);

export default axiosClient;
